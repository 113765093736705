import { ApiResponse, httpClient } from "shared/utils";
import {
  IGetAllSavedProjectDTO,
  IGetSavedProjectAllResponse,
  getSavedProjectEndpoints,
} from "models/savedProject.model";

class SavedProjectAPI {
  static getAllSavedProject({
    page,
    limit,
    ...data
  }: IGetAllSavedProjectDTO): ApiResponse<IGetSavedProjectAllResponse> {
    const { GET_ALL_SAVED_PROJECT } = getSavedProjectEndpoints({ page, limit });
    return httpClient.post<IGetSavedProjectAllResponse>(GET_ALL_SAVED_PROJECT, {
      ...data,
    });
  }
}

export default SavedProjectAPI;
