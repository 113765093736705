import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { FILTER_SLICE_NAME, FilterState, initialState } from "./models";
import {
  getFilterDetails,
  getSearchAddress,
  getSearchInstallerAddress,
  getTotal,
  loadMoreInstallerAddress,
  loadMoreQuotesAddress,
} from "./actionCreators";

export const filterSlice = createSlice({
  name: FILTER_SLICE_NAME,
  initialState,
  reducers: {
    setFilter(state, action) {
      const { payload } = action;
      state.isOpen = payload;
    },
    setCheckboxes(state, action) {
      const { payload } = action;
      state.checkboxes = payload;
    },
    setSliders(state, action) {
      const { payload } = action;
      state.sliders = payload;
    },
    setSearchvalue(state, action) {
      const { payload } = action;
      state.searchValue = payload;
    },
    setIsObjImageContain(state, action) {
      const { payload } = action;
      state.isObjImageContain = payload;
    },
    setOnlyAssignedQuoteSwitch(state, action) {
      const { payload } = action;
      state.isOnlyAssignedQuote = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(getFilterDetails),
        (state: FilterState, action) => {
          const { payload } = action;
          state.filterData = payload.data[0];
        }
      )
      .addMatcher(
        isFulfilled(getSearchAddress),
        (state: FilterState, action) => {
          const { payload } = action;
          state.quotes = payload.data.docs;
          state.quotesPagination = payload.data.pagination;
        }
      )
      .addMatcher(
        isFulfilled(loadMoreQuotesAddress),
        (state: FilterState, action) => {
          const { payload } = action;
          state.quotes = [...state.quotes, ...payload.data.docs];
          state.quotesPagination = payload.data.pagination;
        }
      )
      .addMatcher(
        isFulfilled(getSearchInstallerAddress),
        (state: FilterState, action) => {
          const { payload } = action;
          state.installer = payload.data.docs;
          state.installerPagination = payload.data.pagination;
        }
      )
      .addMatcher(
        isFulfilled(loadMoreInstallerAddress),
        (state: FilterState, action) => {
          const { payload } = action;
          state.installer = [...state.installer, ...payload.data.docs];
          state.installerPagination = payload.data.pagination;
        }
      )
      .addMatcher(isFulfilled(getTotal), (state: FilterState, action) => {
        const { payload } = action;
        state.total = payload.data;
      })
      .addMatcher(
        isPending(
          getFilterDetails,
          getSearchAddress,
          loadMoreQuotesAddress,
          getSearchInstallerAddress,
          loadMoreInstallerAddress,
          getTotal
        ),
        (state: FilterState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(
          getFilterDetails,
          getSearchAddress,
          loadMoreQuotesAddress,
          getSearchInstallerAddress,
          loadMoreInstallerAddress,
          getTotal
        ),
        (state: FilterState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          console.log(error, "error");
        }
      );
  },
});

export const {
  setFilter,
  setCheckboxes,
  setIsObjImageContain,
  setSliders,
  setSearchvalue,
  setOnlyAssignedQuoteSwitch,
} = filterSlice.actions;
export default filterSlice.reducer;
