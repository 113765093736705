import { IApiError } from "models/apiError.model";

export const SUBSCRIPTION_SLICE_NAME = "subscription";

export interface SubscriptionState {
  paywallModal: boolean;
  hubspotModal: boolean;
  isLoading: boolean;
  error: IApiError | null;
  priceId: string | null;
  metadata: {
    obj: string;
    pvsol: string;
  };
}

export const initialState: SubscriptionState = {
  paywallModal: false,
  hubspotModal: false,
  isLoading: false,
  error: null,
  priceId: null,
  metadata: {
    obj: '0',
    pvsol: '0',
  },
};
