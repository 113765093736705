import styled from "styled-components/macro";
interface HoverOrNot {
  isHover?: Boolean;
}
export const PayWallWrapper: any = styled("div")<HoverOrNot>`
  .ant-input-number-handler-wrap {
    display: none !important;
  }
  .ant-input-number-input {
    text-align: center;
  }
  .checkoutMain {
    width: 100%;
    border-radius: 16px;
    /* height: 714px; */
    width: 1240px;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 49px;
    padding-top: 30px;
    @media (max-width: 1200px) {
      width: 100%;
      height: auto;
    }
  }
  .sampleProjectButton {
    height: 50px;
    width: 363px;
    border-radius: 36px;
    box-sizing: border-box;
    background: #ffd75d;
    border-radius: 36px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 100%;
    text-transform: uppercase;
    color: #2d4764;
    cursor: pointer;
    @media (max-width: 1200px) {
      width: 80%;
    }
  }
  .heading_text1 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    color: #2d4764;
  }
  .heading_text2 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #666666;
  }
  .plan_name {
    font-size: xx-large;
    line-height: 32px;
    text-align: center;
    display: flex;
    flex-direction: column;
    span {
      font-size: large;
    }
  }
  .detailPlan_mainContainer {
    width: 100%;
    padding-inline: 1rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: start;
    gap: 35px;
  }
  .inside_main_container {
    box-sizing: border-box;
    width: 350px;
    padding: 20px 15px;
    background: ${({ isHover }) => {
      if (isHover) {
        return "linear-gradient(168.55deg,#fbda61 68.9%,#fad44c 84.53%,#ee7d3e 113.04%,#ee7d3e 129.72%)";
      } else {
        return "#ffffff";
      }
    }};
    border: ${({ isHover }) => {
      if (isHover) {
        return "none";
      } else {
        return "1px solid #dfdfdf";
      }
    }};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    transform: ${({ isHover }) => {
      if (isHover) {
        return "scale(1.06)";
      } else {
        return "0";
      }
    }};
    box-shadow: ${({ isHover }) => {
      if (isHover) {
        return "0px 30px 34px rgba(238, 144, 76, 0.53)";
      } else {
        return "0";
      }
    }};
    background: ${({ isHover }) => {
      if (isHover) {
        return "linear-gradient(168.55deg,#fbda61 68.9%,#fad44c 84.53%,#ee7d3e 113.04%,#ee7d3e 129.72%)";
      } else {
        return "#ffffff";
      }
    }};
    @media (max-width: 600px) {
      width: 86%;
    }
  }
  .flex_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
  }
  .price_detials {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    gap: 5px;
    flex-direction: column;
    .details_box {
      .discount_block{
        margin-bottom: 15px;
      } .title {
        text-align: start;
      }
      .month_status {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .discount_title {
        text-align: start;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 20px */
        span {
          font-family: Nunito;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 100%;
        }
      }
      .discount_description {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }
    .price {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 24px;
      margin-bottom: 5px;
      color: ${(props) => {
        if (props.isHover) {
          return "#404040";
        } else {
          return "#231d4f";
        }
      }};
      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
    .title {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      color: ${(props) => {
        if (props.isHover) {
          return "#404040";
        } else {
          return "#231d4f";
        }
      }};
      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
    .description {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      padding-top: 5px;
      color: ${(props) => {
        if (props.isHover) {
          return "#404040";
        } else {
          return "#3e3637";
        }
      }};
    }
  }
  .bullet {
    display: flex;
    flex-direction: column;
    gap: 11px;
  }
  .bullet_flex {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    .circle {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      background: ${(props) => {
        if (props.isHover) {
          return "#E8CE6C";
        } else {
          return "linear-gradient(180deg,rgba(251, 218, 97, 0.3) 0%,rgba(250, 212, 76, 0.231) 33.16%,rgba(238, 125, 62, 0.093) 100%);";
        }
      }};
      .tickmark:hover {
        fill: #000000;
      }
    }
    .point_name {
      font-family: Nunito;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
  .price_warn {
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  }
  .sub_checkbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .adjust_view_content {
    margin-top: 10px;
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .choos_button {
    border-radius: 24px;
    height: 38px;
    width: 207px;
    border-radius: 24px;
    margin: auto;
    /* cursor: not-allowed; */
    border: ${(props) => {
      if (props.isHover) {
        return "none";
      } else {
        return "1px solid #dfdfdf";
      }
    }};
    background: ${(props) => {
      if (props.isHover) {
        return "#2D4764";
      } else {
        return "#ffffff";
      }
    }};
    color: ${(props) => {
      if (props.isHover) {
        return "#ffffff";
      } else {
        return "#000000";
      }
    }};
  }

  .adjust_button {
    border-radius: 24px;
    height: 38px;
    width: 207px;
    border-radius: 24px;
    margin: auto;
    color: #2d4764;
    background-color: transparent;
    border: 1px solid #2d4764;
  }
  .expand_button {
    border-radius: 50px;
    background: white;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 4px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #666666;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
`;

export const StyledPriceFlex = styled.div<{ isAdjustView: boolean }>`
  display: flex;
  justify-content: ${({ isAdjustView }) => (isAdjustView ? "start" : "end")};
  width: 100%;
  gap: 15px;
  button {
    background: linear-gradient(135deg, #fbda61 2.88%, #ffc371 100%);
    border-radius: 13.5px;
    height: 27px;
    width: 121px;
  }
`;
