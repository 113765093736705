import { IApiError } from "models/apiError.model";
import { IPagination } from "models/pagination.model";
import { Product } from "models/product.model";

export const PRODUCT_SLICE_NAME = "product";

export interface ProductState {
  isLoading: boolean;
  error: IApiError | null;
  products: Product[];
  pagination: IPagination | null;
}

export const initialState: ProductState = {
  isLoading: false,
  error: null,
  products: [],
  pagination: null,
};
