import { IApiError } from "models/apiError.model";
import { IPagination } from "models/pagination.model";
import { ILocationView, IQuote } from "models/quotes.model";

export const QUOTES_SLICE_NAME = "quotes";

export interface QuotesState {
  quotes: IQuote[];
  mapView: ILocationView[] | null;
  selectedQuote: IQuote | null;
  isLoading: boolean;
  error: IApiError | null;
  pagination: IPagination | null;
  maxMin: {
    totalCost: number;
    totalConsumption: number;
  } | null;
}

export const initialState: QuotesState = {
  quotes: [],
  selectedQuote: null,
  mapView: null,
  isLoading: false,
  error: null,
  pagination: null,
  maxMin: null,
};
