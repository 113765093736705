import { ReactNode } from "react";
import { Dropdown as AntdDropdown, DropDownProps } from "antd";

interface IDropdown extends DropDownProps {
  children: ReactNode;
}

const CustomizeDropdown = ({ children, ...props }: IDropdown) => {
  return (
    <>
      <AntdDropdown {...props}>{children}</AntdDropdown>
    </>
  );
};

export default CustomizeDropdown;
