import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  EMAIL_TEMPLATE_SLICE_NAME,
  EmailTemplateState,
  initialState,
} from "./models";
import { getEmailTemplates } from "./actionCreators";

export const emailTemplateSlice = createSlice({
  name: EMAIL_TEMPLATE_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isFulfilled(getEmailTemplates),
      (state: EmailTemplateState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.templates = payload?.data;
      }
    );
    builder.addMatcher(
      isPending(getEmailTemplates),
      (state: EmailTemplateState) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      isRejected(getEmailTemplates),
      (state: EmailTemplateState, action) => {
        const { payload } = action;
        state.isLoading = false;
      }
    );
  },
});

export default emailTemplateSlice.reducer;
