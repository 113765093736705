import { getEmailTemplateEndpoints, IGetEmailTemplatesResponse } from "models/emailTemplate.model";
import { EmailTemplate } from "redux/emailTemplates/models";
import { ApiResponse, httpClient } from "shared/utils";

class EmailTemplateAPI {
  static getEmailTemplates(): ApiResponse<IGetEmailTemplatesResponse> {
    const { GET_EMAIL_TEMPLATES } = getEmailTemplateEndpoints({});
    return httpClient.get<IGetEmailTemplatesResponse>(GET_EMAIL_TEMPLATES);
  }
}

export default EmailTemplateAPI;
