import { IPagination } from "./pagination.model";

export interface IGetQuotesAllResponse {
  data: {
    docs: IQuote[];
    pagination: IPagination;
    searchAbleFields: any;
    maxMin: any;
  };
}
export interface Geometry {
  type: string;
  coordinates: number[];
}
export interface ConnectedProject {
  ImagePresent: boolean;
  installerPresent: boolean;
}

export interface ILocationView {
  geometry: Geometry;
  id: string;
  _id: string;
  status: string;
  connectedProjects: ConnectedProject[];
}

export interface IGetAllQuotesMapViewResponse {
  data: {
    docs: ILocationView[];
    pagination: IPagination;
    searchAbleFields: any;
  };
}
export interface IGetAllQuotesDTO {
  status?: string;
  projectStatus?: string;
  page: number;
  limit: number;
  sortColumn?: string;
  sortOrder?: string;
  maxConsumption?: number;
  minConsumption?: number;
  minRating?: number;
  maxRating?: number;
  maxTotalCost?: number;
  minTotalCost?: number;
  projectType?: string;
  roofType?: string;
  buildingType?: string;
  complete?: string;
  companyName?: string;
}

export interface IGetTotalDTO {
  projectType?: string;
  roofType?: string;
  buildingType?: string;
  complete?: string;
  companyName?: string;
}

export interface IUpdateQuote {
  id: string;
  projectStatus?: string;
  profiles?: any;
  installerPartner?: string[];
}

export interface IGetQuoteByIdDTO {
  id: string;
}

export interface IGetAllQuotesMapViewDTO {
  status?: string;
}

export enum QuotesEndpoints {
  QUOTES = "/quote",
  INSTALLER = "/installer",
  SEARCH = "/search",
  MAP_VIEW = "/map-view",
  FILTER_DATA = "/filter-data",
  TOTAL = "/total",
  UPDATE = "/update",
}

export interface IPaginationProps {
  page?: number;
  limit?: number;
  id?: string;
  sortColumn?: string;
  sortOrder?: string;
}

export const getQuotesEndpoints = ({
  page,
  limit,
  id,
  sortColumn,
  sortOrder,
}: IPaginationProps) => ({
  GET_ALL_QUOTES: `${QuotesEndpoints.QUOTES}${QuotesEndpoints.INSTALLER}${QuotesEndpoints.SEARCH}?page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
  GET_ALL_QUOTES_MAP_VIEW: `${QuotesEndpoints.QUOTES}${QuotesEndpoints.INSTALLER}${QuotesEndpoints.MAP_VIEW}`,
  GET_QUOTE_BY_ID: `${QuotesEndpoints.QUOTES}/${id}`,
  GET_FILTER_DATA: `${QuotesEndpoints.QUOTES}${QuotesEndpoints.INSTALLER}${QuotesEndpoints.FILTER_DATA}`,
  GET_ALL_INSTALLER: `${QuotesEndpoints.INSTALLER}${QuotesEndpoints.SEARCH}?page=${page}&limit=${limit}`,
  GET_TOTAL: `${QuotesEndpoints.QUOTES}${QuotesEndpoints.INSTALLER}${QuotesEndpoints.TOTAL}`,
  UPDATE: `${QuotesEndpoints.QUOTES}${QuotesEndpoints.UPDATE}`,
});

export enum QuotesStatus {
  VERIFIED = "verified",
  NOTAPPROVED = "not verified",
}
export interface IQuote {
  _id: string;
  mapboxAddress: MapboxAddress;
  profiles: Profiles;
  installerPartner: string;
  roofOrientation: string;
  roofTilt: string;
  buildingType: string;
  geohash: string;
  long: string;
  roofTypesList: any[];
  lat: string;
  isActive: boolean;
  batchName: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  __v: number;
  status: string;
  projectType: string;
  roofMaterial: string;
  roofMaterialRating: number;
  roofStatusRating: number;
  roofType: string;
  shadowsRating: number;
  address: string;
  complete: string;
  country: string;
  state: string;
  postcode: string;
  city: string;
  number: string;
  connectedProjects: IConnectedProject[];
  financialRating: string;
  complexityRating: string;
  installerDetails: InstallerDetails[];
}

export interface InstallerDetails {
  _id: string;
  logoUrl: string[];
  companyName: string;
}

export interface IGetTotalResponse {
  data: ITotal;
}

export interface ITotal {
  capacity: number;
  potential: number;
  commercial: number;
  external: number;
  investment: number;
  co2reduction: number;
  totalQuotes: number;
}

export interface IGetQuotesByIdResponse {
  data: IQuote;
}

export interface Profiles {
  green: Green;
  eco: Eco;
  smart: Smart;
}

export interface Green {
  ModelNo?: string;
  changeRequestReason: string;
  imgUrl: any[];
  editedCanvas: any[];
  moduleQuantity: number;
  consumption: number;
  totalCost: number;
  rating: number;
  grn: string;
  roofTilt: string;
  roofType: string;
  roofMaterial: string;
  roofOrientation: string;
  roofMaterialRating: number;
  productionRatio: number;
  totalCostFromProvider: number;
  projectSizeRating: number;
  currentAnnualConsumption: number;
  shadowsRating: number;
  annualSolarGeneration: number;
  roofOrientationRating: number;
  roofStatusRating: number;
  projectType: string;
  annulElectricBill: number;
  roofStructureComplexityRating: number;
  isVerified: string;
  _id: string;
}

export interface Eco {
  changeRequestReason: string;
  imgUrl: any[];
  editedCanvas: any[];
  moduleQuantity: number;
  consumption: number;
  totalCost: number;
  rating: number;
  grn: string;
  roofTilt: string;
  roofType: string;
  roofMaterial: string;
  roofOrientation: string;
  roofMaterialRating: number;
  productionRatio: number;
  totalCostFromProvider: number;
  projectSizeRating: number;
  currentAnnualConsumption: number;
  shadowsRating: number;
  annualSolarGeneration: number;
  roofOrientationRating: number;
  roofStatusRating: number;
  projectType: string;
  annulElectricBill: number;
  roofStructureComplexityRating: number;
  isVerified: string;
  _id: string;
}

export interface Smart {
  changeRequestReason: string;
  imgUrl: any[];
  editedCanvas: any[];
  moduleQuantity: number;
  consumption: number;
  totalCost: number;
  rating: number;
  grn: string;
  roofTilt: string;
  roofType: string;
  roofMaterial: string;
  roofOrientation: string;
  roofMaterialRating: number;
  productionRatio: number;
  totalCostFromProvider: number;
  projectSizeRating: number;
  currentAnnualConsumption: number;
  shadowsRating: number;
  annualSolarGeneration: number;
  roofOrientationRating: number;
  roofStatusRating: number;
  projectType: string;
  annulElectricBill: number;
  roofStructureComplexityRating: number;
  isVerified: string;
  _id: string;
}

export interface MapboxAddress {
  geometry: Geometry;
  number: string;
  locality: string;
  postcode: string;
  city: string;
  address: string;
  country: string;
  state: string;
  complete: string;
  _id: string;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface IFilterData {
  maxConsumption: number;
  minConsumption: number;
  minRating: number;
  maxRating: number;
  maxTotalCost: number;
  minTotalCost: number;
  uniqueRoofTypes: string[];
  uniqueBuildingTypes: string[];
  uniqueProjectTypes: string[];
}

export interface IGetFilterDataResponse {
  data: IFilterData[];
}

export interface IConnectedProject {
  _id: string;
  quoteID: string;
  profile: string;
  energyProductionParams: EnergyProductionParams;
  uid: string;
  version: number;
  components: Components;
  images: Images;
  household: Household;
  financialDetails: FinancialDetails;
  vendor: Vendor;
  pid: any;
  shortId: number;
  secret: string;
  threeDObjDetails: ThreeDobjDetails;
  payment: Payment;
  pdfUrl: any;
  shareProjectLinks: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  bakeModel: BakeModel;
  trackProgress: TrackProgress;
  selectedInstallers: SelectedInstaller[];
}

export interface EnergyProductionParams {
  roofPitch: number;
  increasedCapacityFromAdminPanel: number;
  productionReducedDueToShade: number;
  productionByBatterySimulation: number;
}

export interface Components {
  solar_panel: SolarPanel;
  inverter: Inverter;
  wallBox: WallBox;
  battery: Battery;
  heatpump: Heatpump;
  cars: any[];
  waterHeating: boolean;
  _id: string;
}

export interface SolarPanel {
  item: any;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  preferences: Preferences;
  quantity: number;
}

export interface Preferences {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  Authorisation: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
  applicableMaxCapacity: any;
  "25 Jahre Leistungsgarantie (mind 81%)": boolean;
  "more than 2 roof areas / shading": boolean;
}

export interface Inverter {
  item: any;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  preferences: Preferences2;
  quantity: number;
}

export interface Preferences2 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  Authorisation: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
  applicableMaxCapacity: number;
  "25 Jahre Leistungsgarantie (mind 81%)": boolean;
  "more than 2 roof areas / shading": boolean;
}

export interface WallBox {
  item: any;
  isExcludeCost: boolean;
  preferences: Preferences3;
  extendedWarranty: boolean;
  quantity: number;
}

export interface Preferences3 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  Authorisation: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
  applicableMaxCapacity: any;
  "25 Jahre Leistungsgarantie (mind 81%)": boolean;
  "more than 2 roof areas / shading": boolean;
}

export interface Battery {
  item: any;
  isExcludeCost: boolean;
  preferences: Preferences4;
  extendedWarranty: boolean;
  quantity: number;
}

export interface Preferences4 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  Authorisation: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
  applicableMaxCapacity: any;
  "25 Jahre Leistungsgarantie (mind 81%)": boolean;
  "more than 2 roof areas / shading": boolean;
}

export interface Heatpump {
  item: any;
  isExcludeCost: boolean;
  extendedWarranty: boolean;
  preferences: Preferences5;
  quantity: number;
  configuration: Configuration;
}

export interface Preferences5 {
  "Made in Germany": boolean;
  "Glas / Glas Module": boolean;
  "High performance module >370 Wp": boolean;
  "Full black": boolean;
  "Data visualization": boolean;
  "Load management": boolean;
  "made in europe": boolean;
  hybrid: boolean;
  Extendible: boolean;
  "Emergency Power": boolean;
  Display: boolean;
  "Schnellladung 22KW": boolean;
  App: boolean;
  Authorisation: boolean;
  "Air/Water-Heatpump": boolean;
  "App for heat management": boolean;
  "Warm water heating": boolean;
  applicableMaxCapacity: any;
  "25 Jahre Leistungsgarantie (mind 81%)": boolean;
  "more than 2 roof areas / shading": boolean;
}

export interface Configuration {
  consumptionHeatpump: number;
  totalAreaToBeHeated: number;
  energyDemand: number;
  buildingStandard: number;
  heatDistribution: string;
  oilGasCost: number;
  heatingCapacity: number;
  futureOilGasCostIncrease: number;
  electricityCostHeatpump: number;
  heatLoadBuilding: number;
  blockingTimes: number;
  heatpump_dimension: number;
  solarPowerForHeatpump: number;
}

export interface Images {
  houseTop: HouseTop;
  houseBottom: HouseBottom;
  electricMeter: ElectricMeter;
  ThreeDModels: ThreeDmodels;
  imagePresent: any[];
  _id: string;
}

export interface HouseTop {
  imageURLs: ImageUrl[];
  buildingYearId: string;
  description: string;
}

export interface ImageUrl {
  fileName: string;
  size: string;
  type: string;
  is_active: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  url: string;
  id: string;
}

export interface HouseBottom {
  imageURLs: any[];
  description: string;
}

export interface ElectricMeter {
  imageURLs: any[];
  meterTypeId: string;
  description: string;
}

export interface ThreeDmodels {
  imageURLs: ImageUrl2[];
  planYourSelf: boolean;
  pvSolModifyModel: boolean;
  threeDpvModelSunnyDesign: boolean;
  threeDpvModelSolarEdgeDesign: boolean;
  description: string;
}

export interface ImageUrl2 {
  fileName: string;
  size: string;
  type: string;
  is_active: boolean;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  url: string;
  id: string;
}

export interface Household {
  enteredConsumption: number;
  buildingType: string;
  people: number;
  consumptionProfile: number;
  selfSupply: number;
  kwaPerEmployee: number;
  _id: string;
}

export interface FinancialDetails {
  pricePerKwh: number;
  estimatedYearlyPriceIncrease: number;
  increaseRateByBank: number;
  feedInTariff: number;
  selectedFeedInReason: string;
  isFinancing: boolean;
  expectedReturnTime: number;
  amortizationTime: number;
  electricityCostBefore: number;
  feedInSavings: number;
  loan: Loan;
  _id: string;
}

export interface Loan {
  totalFinancingAmount: number;
  yearlyFinancingAmount: number;
}

export interface Vendor {
  freeChoiceInstaller: boolean;
  selected: any[];
  suggested: any[];
}

export interface ThreeDobjDetails {
  createdLines: any[];
  createdPoints: any[];
  createdLineLoops: any[];
}

export interface Payment {
  status: string;
  paymentIntent: any;
}

export interface BakeModel {
  id: string;
  artifacts: any;
}

export interface TrackProgress {
  percentage: any;
  time: any;
  elapsed: number;
}

export interface SelectedInstaller {
  _id: string;
  iid: string;
  isPaymentDone: boolean;
  isProjectRealized: boolean;
  realizationWeek: any;
  realizationWeekShort: any;
  visitDate: any;
  pid: string;
  status: string;
  __v: number;
}

export enum projectStatus {
  NOT_ACCESSED = "Not accessed",
  USER_HAS_ACCESSED = "User has accessed",
  IMAGE_UPLOADED = "Image uploaded",
  INSTALLER_REQUESTED = "Installer requested",
  INSTALLER_SIGNED = "Installer signed",
  PROJECT_REALIZED = "Project realized",
  OFFER_NOT_ACCEPTED = "Offer not accepted",
  OFFER_PROVIDED = "Offer provided",
}

export interface IdetailsPlanData {
  price: string;
  titile: string;
  description: string;
  bulletPoint: string[];
  index: number;
  planName: string;
}