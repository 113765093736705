import Routes from "routes";
import "i18n/config";
import { initDB } from "react-indexed-db-hook";

export const DBConfig = {
  name: "mapDataDB",
  version: 1,
  objectStoresMeta: [
    {
      store: "mapData",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [{ name: "id", keypath: "id", options: { unique: true } }],
    },
    {
      store: "citiesData",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        {
          name: "id",
          keypath: "id",
          options: { unique: true },
          data: "data",
          abcd: "abcd",
        },
      ],
    },
  ],
};


initDB(DBConfig);


const App = () => {

  return (
    <div className="App">
      <Routes />
    </div>
  );
};

export default App;
