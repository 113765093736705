import React, { useEffect, useRef } from "react";

import { MainContainer } from "./HubSpotBookCall.styles";
import PopupSkeleton from "shared/components/PopupSkeleton/popupSkeleton";
import { useAppDispatch, useAppState } from "redux/store";
import { selectUserDetails } from "redux/user/selectors";
import { closeHubspotModal, closePaywallModal } from "redux/subscription/subscriptionSlice";

export const HubSpotBookCall: React.FC = () => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const userDetails = useAppState(selectUserDetails);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.onload = function (ev) {
      console.log("Hubspot script loaded");
    };
    script.onerror = function (ev) {
      console.error("Hubspot script load error", ev);
    };
    document.body.appendChild(script);
  }, []);

  const meetingsUrl = `https://meetings.hubspot.com/daniel-watz/experte?firstname=${encodeURIComponent(
    userDetails?.firstName ?? ""
  )}&email=${userDetails?.email}&lastname=${encodeURIComponent(
    userDetails?.lastName ?? ""
  )}&Bitte gib vorab deine Adresse an, damit wir uns dein Dach ansehen können.=${
    userDetails?.address?.replace("Germany", "Deutschland") || ""
  }&embed=true`;

  return (
    <PopupSkeleton
      styless={{ paddingTop: "0px" }}
      handleClose={() => {
        dispatch(closeHubspotModal());
      }}
      open={true}
    >
      <MainContainer>
        <div
          className="meetings-iframe-container"
          data-src={meetingsUrl}
          ref={ref.current}
        ></div>
      </MainContainer>
    </PopupSkeleton>
  );
};
