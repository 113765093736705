import { createAsyncThunk } from "@reduxjs/toolkit";
import { QuotesAPI } from "api";
import { IApiError } from "models/apiError.model";
import {
  IGetAllQuotesDTO,
  IGetFilterDataResponse,
  IGetQuotesAllResponse,
  IGetTotalDTO,
  IGetTotalResponse,
} from "models/quotes.model";
import { formatApiError } from "shared/utils";
import { FILTER_SLICE_NAME } from "./models";
import { IGetInstallerResponse } from "models/installer.model";

export const getFilterDetails = createAsyncThunk<
  IGetFilterDataResponse,
  void,
  { serializedErrorType: IApiError }
>(
  `${FILTER_SLICE_NAME}/getFilterDetails`,
  async () => {
    const response = await QuotesAPI.getFilterData();
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getSearchAddress = createAsyncThunk<
  IGetQuotesAllResponse,
  IGetAllQuotesDTO,
  { serializedErrorType: IApiError }
>(
  `${FILTER_SLICE_NAME}/getSearchAddress`,
  async (data: IGetAllQuotesDTO) => {
    const response = await QuotesAPI.getAllQuotes(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const loadMoreQuotesAddress = createAsyncThunk<
  IGetQuotesAllResponse,
  IGetAllQuotesDTO,
  { serializedErrorType: IApiError }
>(
  `${FILTER_SLICE_NAME}/loadMoreQuotesAddress`,
  async (data: IGetAllQuotesDTO) => {
    const response = await QuotesAPI.getAllQuotes(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getSearchInstallerAddress = createAsyncThunk<
  IGetInstallerResponse,
  IGetAllQuotesDTO,
  { serializedErrorType: IApiError }
>(
  `${FILTER_SLICE_NAME}/getSearchInstallerAddress`,
  async (data: IGetAllQuotesDTO) => {
    const response = await QuotesAPI.getAllInstaller(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const loadMoreInstallerAddress = createAsyncThunk<
  IGetInstallerResponse,
  any,
  { serializedErrorType: IApiError }
>(
  `${FILTER_SLICE_NAME}/loadMoreInstallerAddress`,
  async (data: IGetAllQuotesDTO) => {
    const response = await QuotesAPI.getAllInstaller(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getTotal = createAsyncThunk<
  IGetTotalResponse,
  IGetTotalDTO,
  { serializedErrorType: IApiError }
>(
  `${FILTER_SLICE_NAME}/getTotal`,
  async (data: IGetTotalDTO) => {
    const response = await QuotesAPI.getTotal(data);
    return response.data;
  },
  { serializeError: formatApiError }
);
