import { createAsyncThunk } from "@reduxjs/toolkit";
import { MATCHED_PROJECT_SLICE_NAME } from "./models";

import { IApiError } from "models/apiError.model";
import { formatApiError } from "shared/utils";
import {
  IGetAllMatchedProjectDTO,
  IGetMatchedProjectAllResponse,
  IUpdateMatchedProject,
} from "models/matchedProject.model";
import { MatchedProjectAPI, UserAPI } from "api";

export const getAllMatchedProject = createAsyncThunk<
  IGetMatchedProjectAllResponse,
  IGetAllMatchedProjectDTO,
  { serializedErrorType: IApiError }
>(
  `${MATCHED_PROJECT_SLICE_NAME}/getMatchedProject`,
  async (data: IGetAllMatchedProjectDTO) => {
    const response = await MatchedProjectAPI.getAllMatchedProject(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const loadMoreMatchedProject = createAsyncThunk<
  IGetMatchedProjectAllResponse,
  IGetAllMatchedProjectDTO,
  { serializedErrorType: IApiError }
>(
  `${MATCHED_PROJECT_SLICE_NAME}/loadMoreMatchedProject`,
  async (data: IGetAllMatchedProjectDTO) => {
    const response = await MatchedProjectAPI.getAllMatchedProject(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateMatchedProject = createAsyncThunk<
  IGetMatchedProjectAllResponse,
  IUpdateMatchedProject,
  { serializedErrorType: IApiError }
>(
  `${MATCHED_PROJECT_SLICE_NAME}/updateMatchedProject`,
  async (data: IUpdateMatchedProject) => {
    const response = await MatchedProjectAPI.updateMatchedProject(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const sendCustomerOfferEmail = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(
  `${MATCHED_PROJECT_SLICE_NAME}/sendOfferMail`,
  async (data: any) => {
    const response = await UserAPI.sendCustomerOfferEmail(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const generateInstallerPDF = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(
  `${MATCHED_PROJECT_SLICE_NAME}/generatePDF`,
  async (data: any) => {
    const response = await UserAPI.generateInstallerPDF(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateComponents = createAsyncThunk<
  any,
  {id: string, data: any},
  { serializedErrorType: IApiError }
  >(
  `${MATCHED_PROJECT_SLICE_NAME}/updateComponents`,
  async ({id, data}) => {
    const response = await MatchedProjectAPI.updateComponents(id, data);
    return response.data;
  },
  { serializeError: formatApiError }
  )
