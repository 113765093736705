import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  MATCHED_PROJECT_SLICE_NAME,
  matchedProjectState,
  initialState,
} from "./models";
import {
  getAllMatchedProject,
  loadMoreMatchedProject,
  updateMatchedProject,
} from "./actionCreators";
import { projectStatus } from "models/quotes.model";

export const matchedProjectSlice = createSlice({
  name: MATCHED_PROJECT_SLICE_NAME,
  initialState,
  reducers: {
    setInstallerSignedQuote(state, action) {
      const { payload } = action;
      state.installerSignedQuotes = payload;
    },
    setProjectRealizedQuote(state, action) {
      const { payload } = action;
      state.projectRealizedQuotes = payload;
    },
    setMatchedQuote(state, action) {
      const { payload } = action;
      state.matchedProject = payload;
    },
    setOfferProvided(state, action) {
      const { payload } = action;
      state.offerProvidedQuotes = payload;
    },
    setMatchedProject(state, action) {
      const { payload } = action;
      state.matchedProject = state.matchedProject.map((quote) =>
        quote._id === payload._id ? payload : quote
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(getAllMatchedProject),
        (state: matchedProjectState, action) => {
          const { payload, meta } = action;
          switch (meta?.arg?.projectStatus) {
            case projectStatus.INSTALLER_SIGNED:
              state.installerSignedQuotes = payload.data.docs;
              state.installerSignedPagination = payload.data.pagination;
              state.installerSignedMaxMin = payload.data.maxMin[0];
              break;

            case projectStatus.PROJECT_REALIZED:
              state.projectRealizedQuotes = payload.data.docs;
              state.projectRealizedPagination = payload.data.pagination;
              state.projectRealizedMaxMin = payload.data.maxMin[0];
              break;

            case projectStatus.OFFER_NOT_ACCEPTED:
              state.offerNotAcceptedQuotes = payload.data.docs;
              state.offerNotAcceptedPagination = payload.data.pagination;
              state.offerNotAcceptedMaxMin = payload.data.maxMin[0];
              break;
            case projectStatus.OFFER_PROVIDED:
              state.offerProvidedQuotes = payload.data.docs;
              state.offerProvidedPagination = payload.data.pagination;
              state.offerProvidedMaxMin = payload.data.maxMin[0];
              break;

            default:
              state.matchedProject = payload.data.docs;
              state.pagination = payload.data.pagination;
              state.maxMin = payload.data.maxMin[0];
              break;
          }
          state.isLoading = false;
        }
      )
      .addMatcher(
        isPending(
          getAllMatchedProject,
          loadMoreMatchedProject,
          updateMatchedProject
        ),
        (state: matchedProjectState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(
          getAllMatchedProject,
          loadMoreMatchedProject,
          updateMatchedProject
        ),
        (state: matchedProjectState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          console.log(error, "error");
        }
      )
      .addMatcher(
        isFulfilled(loadMoreMatchedProject),
        (state: matchedProjectState, action) => {
          const { payload, meta } = action;
          switch (meta?.arg?.projectStatus) {
            case projectStatus.INSTALLER_SIGNED:
              state.installerSignedQuotes = [
                ...state.installerSignedQuotes,
                ...payload.data.docs,
              ];
              state.installerSignedPagination = payload.data.pagination;
              state.installerSignedMaxMin = payload.data.maxMin[0];
              break;

            case projectStatus.OFFER_PROVIDED:
              state.offerProvidedQuotes = [
                ...state.offerProvidedQuotes,
                ...payload.data.docs,
              ];
              state.offerProvidedPagination = payload.data.pagination;
              state.offerProvidedMaxMin = payload.data.maxMin[0];
              break;

            case projectStatus.PROJECT_REALIZED:
              state.projectRealizedQuotes = [
                ...state.projectRealizedQuotes,
                ...payload.data.docs,
              ];
              state.projectRealizedPagination = payload.data.pagination;
              state.projectRealizedMaxMin = payload.data.maxMin[0];
              break;

            case projectStatus.OFFER_NOT_ACCEPTED:
              state.offerNotAcceptedQuotes = [
                ...state.offerNotAcceptedQuotes,
                ...payload.data.docs,
              ];
              state.offerNotAcceptedPagination = payload.data.pagination;
              state.offerNotAcceptedMaxMin = payload.data.maxMin[0];
              break;

            default:
              state.matchedProject = [
                ...state.matchedProject,
                ...payload.data.docs,
              ];
              state.pagination = payload.data.pagination;
              state.maxMin = payload.data.maxMin[0];
              break;
          }
        }
      )
      .addMatcher(
        isFulfilled(updateMatchedProject),
        (state: matchedProjectState) => {
          state.isLoading = false;
        }
      );
  },
});
export const {
  setInstallerSignedQuote,
  setProjectRealizedQuote,
  setMatchedQuote,
  setOfferProvided,
  setMatchedProject,
} = matchedProjectSlice.actions;
export default matchedProjectSlice.reducer;
