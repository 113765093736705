import { combineReducers } from "@reduxjs/toolkit";
import emailTemplateSlice from "redux/emailTemplates/emailTemplateSlice";
import { EMAIL_TEMPLATE_SLICE_NAME } from "redux/emailTemplates/models";
import filterSlice from "redux/filter/filterSlice";
import { FILTER_SLICE_NAME } from "redux/filter/models";
import matchedProjectSlice from "redux/matchedProject/machedProjectSlice";
import { MATCHED_PROJECT_SLICE_NAME } from "redux/matchedProject/models";
import { PRODUCT_SLICE_NAME } from "redux/product/models";
import productSlice from "redux/product/productSlice";
import { QUOTES_SLICE_NAME } from "redux/quotes/models";
import quotesSlice from "redux/quotes/quotesSlice";
import { SAVED_PROJECT_SLICE_NAME } from "redux/savedProject/models";
import savedProjectSlice from "redux/savedProject/savedProjectSlice";
import { SUBSCRIPTION_SLICE_NAME } from "redux/subscription/models";
import subscriptionSlice from "redux/subscription/subscriptionSlice";
import { USER_SLICE_NAME } from "redux/user/models";
import userSlice from "redux/user/userSlice";

export const rootReducer = combineReducers({
  [USER_SLICE_NAME]: userSlice,
  [QUOTES_SLICE_NAME]: quotesSlice,
  [FILTER_SLICE_NAME]: filterSlice,
  [SAVED_PROJECT_SLICE_NAME]: savedProjectSlice,
  [MATCHED_PROJECT_SLICE_NAME]: matchedProjectSlice,
  [SUBSCRIPTION_SLICE_NAME]: subscriptionSlice,
  [PRODUCT_SLICE_NAME]: productSlice,
  [EMAIL_TEMPLATE_SLICE_NAME]: emailTemplateSlice,
});
