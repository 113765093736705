export interface ICreatePriceDTO {
  unit_amount: number;
  currency: string;
  recurring: PriceRecurring;
  product_data: ProductData;
  metadata: Metadata
}

export interface PriceRecurring {
  interval: string;
}

export interface ProductData {
  name: string;
}

export interface ICreatePriceResponse {
  data: CreatePriceResponse;
}

export interface CreatePriceResponse {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: any;
  livemode: boolean;
  lookup_key: any;
  metadata: Metadata;
  nickname: any;
  product: string;
  recurring: Recurring;
  tax_behavior: string;
  tiers_mode: any;
  transform_quantity: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface Metadata {}

export interface Recurring {
  aggregate_usage: any;
  interval: string;
  interval_count: number;
  trial_period_days: any;
  usage_type: string;
}

export interface ICreateCheckoutDTO {
  priceId: string;
  successUrl: string;
  cancelUrl: string;
  metadata: Metadata;
}

export interface SubscriptionData {
  metadata: Metadata;
}

export interface Metadata {
  obj: number;
  pvsol: number;
}

export interface ICreateCheckoutResponse {
  data: Data;
}

export interface Data {
  id: string;
  object: string;
  after_expiration: any;
  allow_promotion_codes: any;
  amount_subtotal: number;
  amount_total: number;
  automatic_tax: AutomaticTax;
  billing_address_collection: any;
  cancel_url: string;
  client_reference_id: any;
  client_secret: any;
  consent: any;
  consent_collection: any;
  created: number;
  currency: string;
  currency_conversion: any;
  custom_fields: any[];
  custom_text: CustomText;
  customer: any;
  customer_creation: string;
  customer_details: CustomerDetails;
  customer_email: string;
  expires_at: number;
  invoice: any;
  invoice_creation: any;
  livemode: boolean;
  locale: any;
  metadata: Metadata;
  mode: string;
  payment_intent: any;
  payment_link: any;
  payment_method_collection: string;
  payment_method_configuration_details: PaymentMethodConfigurationDetails;
  payment_method_options: any;
  payment_method_types: string[];
  payment_status: string;
  phone_number_collection: PhoneNumberCollection;
  recovered_from: any;
  setup_intent: any;
  shipping_address_collection: any;
  shipping_cost: any;
  shipping_details: any;
  shipping_options: any[];
  status: string;
  submit_type: any;
  subscription: any;
  success_url: string;
  total_details: TotalDetails;
  ui_mode: string;
  url: string;
}

export interface AutomaticTax {
  enabled: boolean;
  status: any;
}

export interface CustomText {
  shipping_address: any;
  submit: any;
  terms_of_service_acceptance: any;
}

export interface CustomerDetails {
  address: any;
  email: string;
  name: any;
  phone: any;
  tax_exempt: string;
  tax_ids: any;
}

export interface Metadata {}

export interface PaymentMethodConfigurationDetails {
  id: string;
  parent: any;
}

export interface PhoneNumberCollection {
  enabled: boolean;
}

export interface TotalDetails {
  amount_discount: number;
  amount_shipping: number;
  amount_tax: number;
}

export interface IGetSubscriptionListResponse {
  data: SubscriptionData;
}

export interface SubscriptionData {
  userId: string;
  subscriptions: Subscriptions;
  payments: Payment[];
}

export interface Subscriptions {
  object: string;
  data: Daum[];
  has_more: boolean;
  url: string;
}

export interface Daum {
  id: string;
  object: string;
  application: any;
  application_fee_percent: any;
  automatic_tax: AutomaticTax;
  billing_cycle_anchor: number;
  billing_thresholds: any;
  cancel_at: any;
  cancel_at_period_end: boolean;
  canceled_at: any;
  cancellation_details: CancellationDetails;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: any;
  default_payment_method: string;
  default_source: any;
  default_tax_rates: any[];
  description: any;
  discount: any;
  ended_at: any;
  items: Items;
  latest_invoice: string;
  livemode: boolean;
  metadata: Metadata4;
  next_pending_invoice_item_invoice: any;
  on_behalf_of: any;
  pause_collection: any;
  payment_settings: PaymentSettings;
  pending_invoice_item_interval: any;
  pending_setup_intent: any;
  pending_update: any;
  plan: Plan2;
  quantity: number;
  schedule: any;
  start_date: number;
  status: string;
  test_clock: any;
  transfer_data: any;
  trial_end: any;
  trial_settings: TrialSettings;
  trial_start: any;
}

export interface AutomaticTax {
  enabled: boolean;
}

export interface CancellationDetails {
  comment: any;
  feedback: any;
  reason: any;
}

export interface Items {
  object: string;
  data: Daum2[];
  has_more: boolean;
  total_count: number;
  url: string;
}

export interface Daum2 {
  id: string;
  object: string;
  billing_thresholds: any;
  created: number;
  metadata: Metadata;
  plan: Plan;
  price: Price;
  quantity: number;
  subscription: string;
  tax_rates: any[];
}

export interface Metadata {}

export interface Plan {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: any;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: Metadata2;
  nickname: any;
  product: string;
  tiers_mode: any;
  transform_usage: any;
  trial_period_days: any;
  usage_type: string;
}

export interface Metadata2 {
  obj: string;
  pvsol: string;
}

export interface Price {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  custom_unit_amount: any;
  livemode: boolean;
  lookup_key: any;
  metadata: Metadata3;
  nickname: any;
  product: string;
  recurring: Recurring;
  tax_behavior: string;
  tiers_mode: any;
  transform_quantity: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface Metadata3 {
  obj: string;
  pvsol: string;
}

export interface Recurring {
  aggregate_usage: any;
  interval: string;
  interval_count: number;
  trial_period_days: any;
  usage_type: string;
}

export interface Metadata4 {}

export interface PaymentSettings {
  payment_method_options: any;
  payment_method_types: any;
  save_default_payment_method: string;
}

export interface Plan2 {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: any;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: Metadata5;
  nickname: any;
  product: string;
  tiers_mode: any;
  transform_usage: any;
  trial_period_days: any;
  usage_type: string;
}

export interface Metadata5 {
  obj: string;
  pvsol: string;
}

export interface TrialSettings {
  end_behavior: EndBehavior;
}

export interface EndBehavior {
  missing_payment_method: string;
}

export interface Payment {
  amount: number;
  currency: string;
  status: string;
  description: string;
  invoice: string;
}




export enum SubscriptionEndpoints {
  PAYMENT = "payments",
  CREATE_PRICE = "/create-price",
  CREATE_CHECKOUT = "/create-checkout-session",
  SUBSCRIPTION_LIST= "/list-subscription"
}

export const getSubscriptionEndpoints = () => ({
  CREATE_PRICE: `${SubscriptionEndpoints.PAYMENT}${SubscriptionEndpoints.CREATE_PRICE}`,
  CREATE_CHECKOUT: `${SubscriptionEndpoints.PAYMENT}${SubscriptionEndpoints.CREATE_CHECKOUT}`,
  SUBSCRIPTION_LIST: `${SubscriptionEndpoints.PAYMENT}${SubscriptionEndpoints.SUBSCRIPTION_LIST}`,
});
