import { PREFERRED_LANGUAGE } from "shared/constants/common";
import appCookiesStorage from "./appCookies";

const DEFAULT_LANGUAGE = "German";
const ALLOWED_LANGS = ["English", "German", "Spanish"];

export type LanguagesShortCodes = "de" | "en" | "es";

export const getLanguageFullName = (code: LanguagesShortCodes): string => {
  if (code === "en") return "English";
  else if (code === "de") return "German";
  else if (code === "es") return "Spanish";
  return "English";
};

export const getShortCode = (lang: string): LanguagesShortCodes => {
  if (lang === "English") return "en";
  else if (lang === "German") return "de";
  else if (lang === "Spanish") return "es";
  return "en";
};

const getLangFromLocalStorage = (): string => {
  return appCookiesStorage.getItem(PREFERRED_LANGUAGE) ?? "";
};

export const isPreferredLangSetAndValid = (): boolean => {
  const lang = getLangFromLocalStorage();
  return ALLOWED_LANGS.includes(lang);
};

export const getUserPreferredLanguage = (): string => {
  let lang = DEFAULT_LANGUAGE;
  if (isPreferredLangSetAndValid()) {
    lang = getLangFromLocalStorage();
  }
  return lang;
};

export const getUserPreferredLanguageShort = (): LanguagesShortCodes => {
  const lang = getUserPreferredLanguage();
  return getShortCode(lang);
};
