const reverseString = (str: string) => str.split("").reverse().join("");

const formatNumberPart = (part: string, thousandsSeparator: string) => {
  const matches = part.match(/\d/g);
  if (!matches) return "";

  const reversed = matches.join("").split("").reverse().join("");
  let result = "";

  for (let i = 0; i < reversed.length; i++) {
    if (i > 0 && i % 3 === 0) {
      result += thousandsSeparator;
    }
    result += reversed[i];
  }

  return reverseString(result);
};

const formatNumber = (val: any, language: string) => {
  const temp = String(val);
  const hasDecimal = temp.includes(".");
  let result = "";

  const thousandsSeparator = language === "de" ? "." : ",";
   const decimalSeparator = language === "de" ? "," : ".";

  if (hasDecimal) {
    const [intPart, decimalPart] = temp.split(".");
    result = `${formatNumberPart(
      intPart,
      thousandsSeparator
    )}${decimalSeparator}${decimalPart}`;
  } else {
    result = formatNumberPart(temp, thousandsSeparator);
  }

  return result;
};

export default formatNumber;
