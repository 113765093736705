import { RootState } from "redux/store";
import { USER_SLICE_NAME, UserState } from "./models";
import { createSelector } from "@reduxjs/toolkit";

const userSelector = (state: RootState): UserState => state[USER_SLICE_NAME];
export const selectUserIsLoading = createSelector(
  userSelector,
  (user: UserState) => user.isLoading
);

export const selectUserDetails = createSelector(
  userSelector,
  (user: UserState) => user.user
);

export const installerDetails = createSelector(
  userSelector,
  (user: UserState) => user.installerDetails
);

export const selectIsAutorized = createSelector(
  userSelector,
  (user: UserState) => user.isAuthorized
);

export const selectFreeDownloadCount = createSelector(
  userSelector,
  (user: UserState) => user.freeDownloadCount
);

export const properties = createSelector(
  userSelector,
  (user: UserState) => user.properties
);

export const selectInstallerDetails = createSelector(
  userSelector,
  (user: UserState) => user?.installerDetails
);

export const selectConstantData = createSelector(
  userSelector,
  (user: UserState) => user?.constants
);

export const selectAllSolarPanels = createSelector(
  userSelector,
  (user: UserState) => user?.solarPanels
);

