import { t } from "i18next";

export enum ResponseStatusCodes {
  SUCCESS = 200,
  ACCEPTED = 202,
  TEMPORARY_REDIRECT = 307,
  BAD_REQUEST = 400,
  NOT_AUTHORIZED = 401,
  SESSION_ENDED = 403,
  NOT_FOUND = 404,
  SOMETHING_WRONG = 418,
  SERVER_ERROR = 500,
}

export const AUTHORIZATION_TOKEN_STORAGE_KEY = "auth_token";
export const INSTALLER_CITY = "installer_city";
export const REFRESH_TOKEN_STORAGE_KEY = "refresh_token";
export const AUTHORIZATION_TOKEN_EXPIRES = 24;
export const PREFERRED_LANGUAGE = "preferredLanguage";

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export const API_URL = `${BASE_API_URL}/api`;

export const areaTypeList = [
  t("Industrial"),
  t("Multi-Family Building"),
  t("Agriculture"),
  t("Administration"),
  t("Single-Family Building"),
];

export const roofTypeList = [
  t("Folded plate roof"),
  t("Trapezoidal Profiles"),
  t("Gable roof"),
  t("Hip roof"),
  t("Tent roof"),
  t("Arched roof"),
  t("Flatroof"),
  t("Single pitch roof"),
  t("Half hipped roof"),
  t("Wall dormer"),
];

export const rateNameList = ["Domestic", "Commercial", "Other"];
