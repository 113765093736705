import { IApiError } from "models/apiError.model";
import { IPagination } from "models/pagination.model";
import { IMatchedQuote } from "models/matchedProject.model";

export const MATCHED_PROJECT_SLICE_NAME = "matchedProject";

export interface matchedProjectState {
  matchedProject: IMatchedQuote[];
  isLoading: boolean;
  error: IApiError | null;
  pagination: IPagination | null;
  maxMin: {
    totalCost: number;
    totalConsumption: number;
  } | null;
  installerSignedQuotes: any;
  installerSignedPagination: IPagination | null;
  installerSignedMaxMin: {
    totalCost: number;
    totalConsumption: number;
  } | null;
  projectRealizedQuotes: any;
  projectRealizedPagination: IPagination | null;
  projectRealizedMaxMin: {
    totalCost: number;
    totalConsumption: number;
  } | null;
  offerNotAcceptedQuotes: any;
  offerNotAcceptedPagination: IPagination | null;
  offerNotAcceptedMaxMin: {
    totalCost: number;
    totalConsumption: number;
  } | null;
  offerProvidedQuotes: any;
  offerProvidedPagination: IPagination | null;
  offerProvidedMaxMin: {
    totalCost: number;
    totalConsumption: number;
  } | null;
}

export const initialState: matchedProjectState = {
  matchedProject: [],
  isLoading: false,
  error: null,
  pagination: null,
  maxMin: null,
  installerSignedQuotes: [],
  installerSignedPagination: null,
  installerSignedMaxMin: null,
  projectRealizedQuotes: [],
  projectRealizedPagination: null,
  projectRealizedMaxMin: null,
  offerNotAcceptedQuotes: [],
  offerNotAcceptedPagination: null,
  offerNotAcceptedMaxMin: null,
  offerProvidedQuotes: [],
  offerProvidedPagination: null,
  offerProvidedMaxMin: null,
};
