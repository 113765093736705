import { IApiError } from "models/apiError.model";
import { IInstaller } from "models/installer.model";
import { IPagination } from "models/pagination.model";
import { IFilterData, IQuote, ITotal } from "models/quotes.model";

export const FILTER_SLICE_NAME = "filter";

export interface FilterState {
  isOpen: boolean;
  filterData: IFilterData | null;
  total: ITotal | null;
  isLoading: boolean;
  quotes: IQuote[];
  installer: IInstaller[];
  quotesPagination: IPagination | null;
  installerPagination: IPagination | null;
  error: IApiError | null;
  checkboxes: Record<string, string[]>;
  sliders: Record<string, number[]>;
  searchValue: any;
  isObjImageContain: boolean;
  isOnlyAssignedQuote: boolean;
}

export const initialState: FilterState = {
  quotes: [],
  installer: [],
  quotesPagination: null,
  installerPagination: null,
  isOpen: false,
  filterData: null,
  total: null,
  isLoading: false,
  error: null,
  isObjImageContain: false,
  checkboxes: {},
  sliders: {},
  searchValue: {},
  isOnlyAssignedQuote: false,
};
