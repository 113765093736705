import { ApiResponse, httpClient } from "shared/utils";
import {
  IGetAllMatchedProjectDTO,
  IGetMatchedProjectAllResponse,
  IUpdateMatchedProject,
  getMatchedProjectEndpoints,
} from "models/matchedProject.model";

class MatchedProjectAPI {
  static getAllMatchedProject({
    page,
    limit,
    ...data
  }: IGetAllMatchedProjectDTO): ApiResponse<IGetMatchedProjectAllResponse> {
    const { GET_ALL_MATCHED_PROJECT } = getMatchedProjectEndpoints({
      page,
      limit,
    });
    return httpClient.put<IGetMatchedProjectAllResponse>(
      GET_ALL_MATCHED_PROJECT,
      { ...data }
    );
  }

  static updateMatchedProject({
    id,
    ...data
  }: IUpdateMatchedProject): ApiResponse<IGetMatchedProjectAllResponse> {
    const { UPDATE_MATCHED_PROJECT } = getMatchedProjectEndpoints({ id });
    return httpClient.put<IGetMatchedProjectAllResponse>(
      UPDATE_MATCHED_PROJECT,
      { ...data }
    );
  }

  static updateComponents(id: string, data: any): ApiResponse<any> {
    const { UPDATE_COMPONENTS } = getMatchedProjectEndpoints({ id });
    return httpClient.put<any>(UPDATE_COMPONENTS, data);
  }
}

export default MatchedProjectAPI;
