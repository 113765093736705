import { useState, useEffect } from "react";

export default function useWidth() {
  const [width, setwidth]: any = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setwidth(window.innerWidth);
    });
  }, []);

  return { width };
}
