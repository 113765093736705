const BASE_URL = "https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/3D_package_images/"

const BlueSolarPanel = `${BASE_URL}blueSolarPanel.svg`;
const Capture = `${BASE_URL}screenShotIcon.svg`;
const Home = `${BASE_URL}carbon_home.svg`;
const ZoomIn = `${BASE_URL}carbon_zoom-in.svg`;
const ZoomOut = `${BASE_URL}carbon_zoom-out.svg`;
const DroneYellow = `${BASE_URL}Droneyellow.svg`;
const FullScreenIcon = `${BASE_URL}fullscreen.svg`;
const Drone = `${BASE_URL}gis_drone.svg`;
const HandWhite = `${BASE_URL}Handwhite.svg`;
const HomeYellow = `${BASE_URL}Homeyellow.svg`;
const Lock = `${BASE_URL}Lock.svg`;
const MaxWidth = `${BASE_URL}maximumWidth.svg`;
const MesureYellow = `${BASE_URL}Measureyellow.svg`;
const PaintBucket = `${BASE_URL}paint-bucket.png`;
const Light = `${BASE_URL}ph_hand-light.svg`;
const Planet = `${BASE_URL}ph_planet.svg`;
const Polygon = `${BASE_URL}ph_polygon.svg`;
const PlanetYellow = `${BASE_URL}Planetyellow.svg`;
const PopupWhite = `${BASE_URL}popupWhite.svg`;
const PopupYellow = `${BASE_URL}popupYellow.svg`;
const SolarIcon = `${BASE_URL}solar-icon.png`;
const SolarPanel = `${BASE_URL}solarPanel.svg`;
const SolarPanelYellow = `${BASE_URL}SolarPanelYellow.svg`;
const ScreenShotIcon = `${BASE_URL}screenShotIcon.svg`;
const CloseIcon = `${BASE_URL}Close.svg`;
const DeleteIcon = `${BASE_URL}DeleteIcon.svg`;
const DownIcon = `${BASE_URL}downIcon.svg`;
const DragIcon = `${BASE_URL}DragIcon.svg`;
const Info = `${BASE_URL}Info.svg`;
const Loader = `${BASE_URL}loader.gif`;
const Undo = `${BASE_URL}undo.png`;
const WhiteDot = `${BASE_URL}whiteDot.svg`;
const UpIcon = `${BASE_URL}UpIcon.svg`;

export {
  BlueSolarPanel,
  Capture,
  Home,
  ZoomIn,
  ZoomOut,
  DroneYellow,
  FullScreenIcon,
  Drone,
  HandWhite,
  HomeYellow,
  Lock,
  MaxWidth,
  MesureYellow,
  PaintBucket,
  Light,
  Planet,
  Polygon,
  PlanetYellow,
  PopupWhite,
  PopupYellow,
  SolarIcon,
  SolarPanel,
  SolarPanelYellow,
  ScreenShotIcon,
  CloseIcon,
  DeleteIcon,
  DownIcon,
  DragIcon,
  Info,
  Loader,
  Undo,
  UpIcon,
  WhiteDot
};