import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// import { IdetailsPlanData } from "./cardsData";
import { PayWallWrapper } from "./PayWallStyled";

import DetailsPlan from "../Detailplan/DetailsPlan";
import PopupSkeleton from "shared/components/PopupSkeleton/popupSkeleton";
import { closePaywallModal } from "redux/subscription/subscriptionSlice";
import { useAppDispatch } from "redux/store";

function getDetailsPlanData(): any[] {
  return [
    {
      price: t("60 €"),
      titile: t("First 3 x Planning data"),
      planName: "Free",
      description: t("PLAN_DESCRIPTION_1"),
      bulletPoint: [
        t("3 Planning data (OBJ) for import to planning system"),
        t("unlimited self-measuring"),
        t("up to 5000 m² roofsize per object"),
      ],
      index: 0,
    },
    {
      price: t("200 €"),
      titile: t("Energy consulting"),
      planName: "Premium",
      description: t("PLAN_DESCRIPTION_2"),
      bulletPoint: [
        t("4 Planning data (OBJ) or 2 PV SOL files - planning by SolarHub"),
        t("unlimited self-measuring"),
        t("delivery in 2-7 days"),
        t("Free 3D-Model-viewer"),
        t("up to 5000 m² roofsize per object"),
        t("99.91% data accuracy (3cm)"),
      ],
      index: 1,
    },
    {
      price: t("750 €"),
      titile: t("Individual Price"),
      planName: "Enterprise",
      description: t("PLAN_DESCRIPTION_3"),
      bulletPoint: [
        t("Planning data (OBJ) individual agreement"),
        t("unlimited self-measuring"),
        t("delivery in 2-7 days"),
        t("Free 3D-Model-viewer"),
        t("more than 5000 m² roofsize per object"),
        t("99.91% data accuracy (3cm)"),
      ],
      index: 2,
    },
  ];
}
const PayWall: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dataOfPaywall, setDataOfPaywall] = useState(getDetailsPlanData());

  // useEffect(() => {
  //   setDataOfPaywall(getDetailsPlanData());
  // }, []);

  // const handleClose = useCallback(() => dispatch(closePopup(POPUP_KEYS.pay_wall)), [dispatch]);
  const handleClose = () => {
    dispatch(closePaywallModal());
  };
  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <PayWallWrapper>
        <div className="checkoutMain">
          <div className="hading_work">
            <div className="heading_text1">
              {t("Photogrammetry Models by SolarHub")}
            </div>
            <div className="heading_text2">
              {t("Achieving project success with precise data")}
            </div>
          </div>
          <div className="detailPlan_mainContainer">
            {dataOfPaywall.map((data: any) => (
              <DetailsPlan data={data} key={data.index} />
            ))}
          </div>
        </div>
      </PayWallWrapper>
    </PopupSkeleton>
  );
};

export default PayWall;
