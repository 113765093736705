import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubscriptionAPI } from "api";
import { IApiError } from "models/apiError.model";
import {
  ICreateCheckoutDTO,
  ICreateCheckoutResponse,
  ICreatePriceDTO,
  ICreatePriceResponse,
  IGetSubscriptionListResponse,
} from "models/subscription.model";
import { formatApiError } from "shared/utils";
import { SUBSCRIPTION_SLICE_NAME } from "./models";

export const createPrice = createAsyncThunk<
  ICreatePriceResponse,
  ICreatePriceDTO,
  { serializedErrorType: IApiError }
>(
  `${SUBSCRIPTION_SLICE_NAME}/createPrice`,
  async (data: ICreatePriceDTO) => {
    const response = await SubscriptionAPI.createPrice(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const createCheckout = createAsyncThunk<
  ICreateCheckoutResponse,
  ICreateCheckoutDTO,
  { serializedErrorType: IApiError }
>(
  `${SUBSCRIPTION_SLICE_NAME}/createCheckout`,
  async (data: ICreateCheckoutDTO) => {
    const response = await SubscriptionAPI.createCheckout(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getSubscriptionList = createAsyncThunk<
  IGetSubscriptionListResponse,
  void,
  { serializedErrorType: IApiError }
>(
  `${SUBSCRIPTION_SLICE_NAME}/getSubscriptionList`,
  async () => {
    const response = await SubscriptionAPI.getSubscriptionList();
    return response.data;
  },
  { serializeError: formatApiError }
);
