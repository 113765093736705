import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu as AntdMenu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  LangaugeItems,
  getLangaugeMenuConfig,
} from "pages/Layout/helpers/getLangaugeMenuConfig";
import { PREFERRED_LANGUAGE } from "shared/constants/common";
import { Button, Dropdown, Menu } from "shared/ui";
import appCookiesStorage from "shared/utils/appCookies";
import { DownIcon } from "pages/listing/components/ObjViewerModal/assets";

const LanguageDropdown = () => {
  const { t, i18n } = useTranslation();
  const handleClick = useCallback(
    ({ key }: { key: string }) => {
      const keyCode = key === LangaugeItems.ENGLISH ? "en" : "de";
      if (i18n.language === keyCode) return;
      void i18n.changeLanguage(keyCode);
      appCookiesStorage.setItem(PREFERRED_LANGUAGE, key);
    },
    [i18n]
  );
  const menuConfig = getLangaugeMenuConfig();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectedLangConfig = menuConfig.find(
    (item) =>
      i18n.language === (item.key === LangaugeItems.ENGLISH ? "en" : "de")
  );

  const langaugeMenu = useMemo(() => {
    const items = menuConfig.map((item) => (
      <AntdMenu.Item key={item.key}>
        {item.title}
      </AntdMenu.Item>
    ));
    return <Menu onClick={handleClick}>{items}</Menu>;
  }, [handleClick, menuConfig]);

  return (
    <Dropdown
      overlay={langaugeMenu}
      trigger={["click"]}
      placement="bottomCenter"
      onVisibleChange={(visible) => setIsDropdownOpen(visible)}
    >
      <div className="flex cursor-pointer" >
        <img
          src={selectedLangConfig?.flag}
          alt={`${selectedLangConfig?.title} flag`}
          style={{ width: "45px", height: "24px" }}
        />
        <img className={`transition-transform duration-300 ${
            isDropdownOpen ? "rotate-180" : "" }`}
             src={DownIcon}/>
      </div>
    </Dropdown>

    
  );
};

export default LanguageDropdown;
