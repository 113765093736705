export interface IAuthResponse {
  data: {
    token: string;
    user: IUserDetails;
    installerDetails: IInstaller | any;
  };
}

export interface IUserDetailsResponse {
  data: IUserDetails;
}
export interface IUserDetails {
  flag: Flag;
  lastLog: LastLog;
  selectedViews: SelectedViews;
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  isFromFirebase: boolean;
  mobile: string;
  address: string;
  installerCity: string;
  role: string;
  settings: Setting[];
  language: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  hubSpotContactId: string;
  installerPartner: string;
  downloads: Download[];
  id: string;
}

export interface Flag {
  needToShowWebinarWelcome: boolean;
}

export interface LastLog {
  pid: string;
  address: string;
}

export interface SelectedViews {
  SAVED_PROJECTS: SavedProjects;
  CHANGE_REQUEST: ChangeRequest;
  PRODUCTS: Products;
  USERS: Users;
  QUOTES: Quotes;
  INSTALLERS: Installers;
  WEBINAR_DETAILS: WebinarDetails;
  PARTNER_CITIES: PartnerCities;
}

export interface SavedProjects {
  tabs: any[];
}

export interface ChangeRequest {
  tabs: any[];
}

export interface Products {
  tabs: any[];
}

export interface Users {
  tabs: any[];
}

export interface Quotes {
  tabs: any[];
}

export interface Installers {
  tabs: any[];
}

export interface WebinarDetails {
  tabs: any[];
}

export interface PartnerCities {
  tabs: any[];
}

export interface Setting {
  tableSettings: TableSettings;
  _id: string;
  uid: string;
}

export interface TableSettings {
  products: Products2;
  savedProjects: SavedProjects2;
  userDetails: UserDetails;
  installers: Installers2;
  quotes: Quotes2;
  _id: string;
}

export interface Products2 {
  selectedColumn: any[];
  selectedFilters: any[];
}

export interface SavedProjects2 {
  selectedColumn: any[];
  selectedFilters: any[];
}

export interface UserDetails {
  selectedColumn: any[];
  selectedFilters: any[];
}

export interface Installers2 {
  selectedColumn: any[];
  selectedFilters: any[];
}

export interface Quotes2 {
  selectedColumn: any[];
  selectedFilters: any[];
}

export interface Download {
  quoteId: string;
  isPvSol: boolean;
  isObj: boolean;
  _id: string;
}

export interface ILoginDTO {
  email?: string;
  password?: string;
  is_installer_user?: boolean;
  adminToken?: string;
}

export interface IInstaller {
  address: string;
  email: string;
  geometry: Geometry;
  installerId: string;
  lat: number;
  lng: number;
  logoUrl: any;
  name: string;
  constants: IConstant[] | any;
}

export interface ISendEmail {
  installerPartner: string;
  pvSolFile?: string;
  objFile?: string;
}

export interface IStorePdf {
  quoteId: string;
  pdfUrl?: string;
  docUrl?: string;
}

export interface IUpdateDownloadCountDTO {
  quoteId: string;
  isPvSol?: boolean;
  isObj?: boolean;
}

export interface IGetFreeDownloadCount {
  data: GetFreeDownloadCount[];
}
export interface GetFreeDownloadCount {
  freeDownloadCountObj: number;
  freeDownloadCountPvsol: number;
  properties: any[];
  constants: IConstant[];
}

export interface IInstaller {
  geometry: Geometry;
  userHistory: string;
  _id: string;
  address: string;
  iid: string;
  companyUrl: string;
  LastUpdate: number;
  Muttergesellschaft: string;
  "Netzanlagen/Inselanlagen": string;
  userRating: number;
  email: string;
  WechselrichterLieferanten: string;
  installerStatus: boolean;
  lat: number;
  lng: number;
  adminRating: number;
  logoUrl: any;
  InstallaliertSeit: string;
  geohash: string;
  companyName: string;
  selfLeads: any[];
  isRegistered: boolean;
  InstallationenIn: string;
  website: string;
  InstallationSizeIn: string;
  SonstigerService: string;
  SolarPanelSuppliers: string;
  telephone: string;
  partnerFrontEnd: boolean;
  partnerSlug: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ICustomerOffer {
  pdfUrl?: string;
  name: string;
  system_size: string;
  projectId: string;
  email: string;
  language: string;
  Addr1: string;
  projectUrl: string;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}
export interface IConstant {
  type: string;
  isActive: boolean;
  priceB2b: number;
  priceB2c: number;
  title: string;
  _id: string;
  selected?: boolean;
}

export enum UserEndpoints {
  LOGIN = "/user/installer/login",
  USER = "/user",
  MULTIPLEACCESS = "/installer/userAceess",
  DOWNLOAD_COUNT = "/user/downloadupdate",
  FREE_DOWNLOAD_COUNT = "/constant",
  INSTALLER_UPDATE = "/installer",
  SEND_EMAIL = "/installer/sendEmail",
  SEND_OFFER_MAIL = "/email/sendOfferMail",
  STORE_PDF_DATA = "/installer/storePDFdata",
  CUSTOMER_OFFER = "/installer/sendOfferToCustomer",
  INSTALLERS_PDF = "/comman/installerPdf",
  GET_ALL_PANEL = "/product/product-obj-files",
}
