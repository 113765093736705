import { createAsyncThunk } from "@reduxjs/toolkit";
import { SAVED_PROJECT_SLICE_NAME } from "./models";
import {
  IGetAllSavedProjectDTO,
  IGetSavedProjectAllResponse,
} from "models/savedProject.model";
import { IApiError } from "models/apiError.model";
import { formatApiError } from "shared/utils";
import { SavedProjectAPI } from "api";

export const getAllSavedProject = createAsyncThunk<
  IGetSavedProjectAllResponse,
  IGetAllSavedProjectDTO,
  { serializedErrorType: IApiError }
>(
  `${SAVED_PROJECT_SLICE_NAME}/getSavedProject`,
  async (data: IGetAllSavedProjectDTO) => {
    const response = await SavedProjectAPI.getAllSavedProject(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const loadMoreSavedProject = createAsyncThunk<
  IGetSavedProjectAllResponse,
  IGetAllSavedProjectDTO,
  { serializedErrorType: IApiError }
>(
  `${SAVED_PROJECT_SLICE_NAME}/loadMoreSavedProject`,
  async (data: IGetAllSavedProjectDTO) => {
    const response = await SavedProjectAPI.getAllSavedProject(data);
    return response.data;
  },
  { serializeError: formatApiError }
);
