import { RootState } from "redux/store";
import { SUBSCRIPTION_SLICE_NAME, SubscriptionState } from "./models";
import { createSelector } from "@reduxjs/toolkit";

const subscriptionSelector = (state: RootState): SubscriptionState =>
  state[SUBSCRIPTION_SLICE_NAME];

export const isSubscriptionModalOpen = createSelector(
  subscriptionSelector,
  (subscription: SubscriptionState) => subscription.paywallModal
);

export const selectSubscriptionId = createSelector(
  subscriptionSelector,
  (subscription: SubscriptionState) => subscription.priceId
);

export const selectMetaData = createSelector(
  subscriptionSelector,
  (subscription: SubscriptionState) => subscription.metadata
);

export const selectIsHubspotModalOpen = createSelector(
  subscriptionSelector,
  (subscription: SubscriptionState) => subscription.hubspotModal
);
