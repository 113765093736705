import "components/templates/DashBoard.css";
import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import Content from "./../organisms/Content";
import LeftSidebar from "components/organisms/left_sidebar";
import { useAppDispatch, useAppState } from "redux/store";
import {
  getFreeDownloadCount,
  getUserDetails,
} from "redux/user/actionCreators";
import PayWall from "pages/overviewNew/Components/Paywall/PayWall";
import {
  isSubscriptionModalOpen,
  selectIsHubspotModalOpen,
} from "redux/subscription/selectors";
import { getSubscriptionList } from "redux/subscription/actionCreators";
import { HubSpotBookCall } from "pages/overviewNew/Components/HubSpotBookCall/HubSpotBookCall";

interface DashBoardLayoutProps extends React.HTMLAttributes<Element> {
  children: React.ReactNode;
}

const DashBoardLayout = ({ children }: DashBoardLayoutProps) => {
  const [state, setstate] = useState(true);
  const [mobile_state, setmobile_state] = useState(false);
  const [switchview, setswitchview] = useState(1);
  const isPaywallModalOpen = useAppState(isSubscriptionModalOpen);
  const isHubspotModalOpen = useAppState(selectIsHubspotModalOpen);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getSubscriptionList());
    dispatch(getFreeDownloadCount())
  }, []);

  return (
    <Layout className="relative" style={{ height: "calc(100vh)" }}>
      <LeftSidebar
        state={state}
        toggle={() => setstate(!state)}
        mobile_state={mobile_state}
        setmobile_state={setmobile_state}
        switchview={switchview}
        setswitchview={setswitchview}
      />
      <Content
        state={state}
        mobile_state={mobile_state}
        toggle={() => setstate(!state)}
        toggle_mobile={() => setmobile_state(!mobile_state)}
        switchview={switchview}
        setswitchview={setswitchview}
      >
        {children}
      </Content>
      {isPaywallModalOpen && <PayWall />}
      {isHubspotModalOpen && <HubSpotBookCall />}
    </Layout>
  );
};

export default DashBoardLayout;
