import styled from "styled-components/macro";

import { VariantTypes } from "./CloseButton.types";

interface CloseButtonDivProps {
  variant: VariantTypes;
}
export const CloseButtonDiv = styled.div<CloseButtonDivProps>`
  position: relative;
  width: 30px;
  height: 30px;
  background-color: ${(props) =>
    props.variant === "circle-hoverable" ? "#373737aa" : "transparent"};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .line {
    width: 2px;
    height: 60%;
    background-color: ${(props) =>
      props.variant === "circle-hoverable" ? "white" : "var(--blue)"};
    position: relative;
    transform-origin: center;
    border-radius: 100%;
  }

  & .l1 {
    transform: rotate(45deg);
    left: 1px;
  }

  & .l2 {
    transform: rotate(-45deg);
    right: 1px;
  }

  &:hover {
    cursor: pointer;
    filter: ${(props) => (props.variant === "circle-hoverable" ? "contrast(200%)" : "initial")};

    & .line {
      background-color: ${(props) =>
        props.variant === "circle-hoverable" ? "#00000aa" : "var(--blue)"};
    }
  }
`;
