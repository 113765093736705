import { createAsyncThunk } from "@reduxjs/toolkit";
import { QUOTES_SLICE_NAME } from "./models";
import { QuotesAPI } from "api";
import {
  IGetAllQuotesDTO,
  IGetAllQuotesMapViewDTO,
  IGetAllQuotesMapViewResponse,
  IGetQuoteByIdDTO,
  IGetQuotesAllResponse,
  IGetQuotesByIdResponse,
  IUpdateQuote,
} from "models/quotes.model";
import { IApiError } from "models/apiError.model";
import { formatApiError } from "shared/utils";

export const getAllQuotes = createAsyncThunk<
  IGetQuotesAllResponse,
  IGetAllQuotesDTO,
  { serializedErrorType: IApiError }
>(
  `${QUOTES_SLICE_NAME}/getQuotes`,
  async (data: IGetAllQuotesDTO) => {
    const response = await QuotesAPI.getAllQuotes(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const loadMoreQuotes = createAsyncThunk<
  IGetQuotesAllResponse,
  IGetAllQuotesDTO,
  { serializedErrorType: IApiError }
>(
  `${QUOTES_SLICE_NAME}/loadMoreQuotes`,
  async (data: IGetAllQuotesDTO) => {
    const response = await QuotesAPI.getAllQuotes(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getAllQuotesMapView = createAsyncThunk<
  IGetAllQuotesMapViewResponse,
  IGetAllQuotesMapViewDTO,
  { serializedErrorType: IApiError }
>(
  `${QUOTES_SLICE_NAME}/getmapview`,
  async (data: IGetAllQuotesMapViewDTO) => {
    const response = await QuotesAPI.getAllQuotesMapView(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getQuoteById = createAsyncThunk<
  IGetQuotesByIdResponse,
  IGetQuoteByIdDTO,
  { serializedErrorType: IApiError }
>(
  `${QUOTES_SLICE_NAME}/getQuoteById`,
  async (data: IGetQuoteByIdDTO) => {
    const response = await QuotesAPI.getQuoteById(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateQuote = createAsyncThunk<
  IGetQuotesByIdResponse,
  IUpdateQuote,
  { serializedErrorType: IApiError }
>(
  `${QUOTES_SLICE_NAME}/updateQuote`,
  async (data: IUpdateQuote) => {
    const response = await QuotesAPI.updateQuote(data);
    return response.data;
  },
  { serializeError: formatApiError }
);