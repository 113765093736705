import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as TickMark } from "../../../../assets/svgs/svgs/TickMark.svg";
import { ReactComponent as TickMarkBlack } from "../../../../assets/svgs/svgs/TickMarkBlack.svg";
import { IdetailsPlanData } from "models/quotes.model";
import { PayWallWrapper, StyledPriceFlex } from "../Paywall/PayWallStyled";
import { useAppDispatch, useAppState } from "redux/store";
import { createCheckout, createPrice } from "redux/subscription/actionCreators";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { BackIcon } from "assets";
import { Checkbox, Col, InputNumber, Row, Slider } from "antd";
import { selectSubscriptionId } from "redux/subscription/selectors";
import showErrorToast from "shared/components/ErrorToast/ErrorToast";
import {
  closePaywallModal,
  openHubspotModal,
} from "redux/subscription/subscriptionSlice";
import showSuccessToast from "shared/components/SuccessToast/SuccessToast";
import formatNumber from "shared/utils/formatNumber";

interface deatilPlan {
  data: IdetailsPlanData;
  key: number;
  isfrom?: boolean;
}

const DetailsPlan: React.FC<deatilPlan> = ({ data, isfrom }) => {
  const { t, i18n } = useTranslation();
  const [isHover, setIsHover] = useState<Boolean>(false);
  const [showBulletPoints, setShowBulletPoints] = useState<boolean>(false);
  const [isAdjustView, setIsAdjustView] = useState<boolean>(false);
  const [increaseMonthly, setIncreaseMonthly] = useState<boolean>(false);
  const [increaseThisMonth, setIncreaseThisMonth] = useState<boolean>(false);
  const [increaseObj, setIncreaseObj] = useState<boolean>(false);
  const [increasePvsol, setIncreasePvsol] = useState<boolean>(false);
  const [showSubCheckboxes, setShowSubCheckboxes] = useState<boolean>(false);
  const [showSubCheckboxesMonthly, setShowSubCheckboxesMonthly] =
    useState<boolean>(false);
  const [objCount, setObjCount] = useState<number>(0);
  const [pvsolCount, setPvsolCount] = useState<number>(0);
  const subscriptionId = useAppState(selectSubscriptionId);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (data.price === "200 €" || isfrom) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  }, [data.price, isfrom]);

  const handlePurchasePlan = useCallback(async () => {
    if (data.price === "60 €") {
      dispatch(closePaywallModal());
      showSuccessToast({ message: "Already using free plan" });
      showErrorToast({ message: t("Please select premuim plan") });
      return;
    }
    if (data.price === "750 €") {
      dispatch(closePaywallModal());
      dispatch(openHubspotModal());
      showSuccessToast({ message: t("Chat with us for enterprise plan") });
      showErrorToast({ message: t("Please select premuim plan") });
      return;
    }
    const totalPrice = 200 + objCount * 100 + pvsolCount * 200;

    const response: any = await dispatch(
      createPrice({
        unit_amount: totalPrice * 100,
        currency: "eur",
        recurring: {
          interval: "month",
        },
        product_data: {
          name: "premium",
        },
        metadata: {
          obj: objCount,
          pvsol: pvsolCount,
        },
      })
    );

    await dispatch(
      createCheckout({
        priceId: response?.payload?.data?.id,
        successUrl: "https://installer.solarhub24.de/overview",
        cancelUrl: "https://installer.solarhub24.de/overview",
        metadata: {
          obj: objCount,
          pvsol: pvsolCount,
        },
      })
    );
  }, [
    subscriptionId,
    objCount,
    pvsolCount,
    openHubspotModal,
    closePaywallModal,
  ]);

  const handleIncreaseMonthlyChange = (checked: boolean) => {
    setIncreaseMonthly(checked);
    if (checked === false) {
      setObjCount(0);
    }
  };

  const handleIncreaseThisMonthChange = (checked: boolean) => {
    setIncreaseThisMonth(checked);
  };

  const handleObjCountChange = (value: number) => {
    setObjCount(value);
  };

  const handlePvsolCountChange = (value: number) => {
    setPvsolCount(value);
  };

  const handleIncreaseObjChange = (checked: boolean) => {
    setIncreaseObj(checked);
    if (checked === false) {
      setObjCount(0);
    }
  };
  const handleIncreasePvsolChange = (checked: boolean) => {
    setIncreasePvsol(checked);
    if (checked === false) {
      setPvsolCount(0);
    }
  };

  return (
    <PayWallWrapper isHover={isHover}>
      <div
        className="inside_main_container"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => {
          if (isfrom ?? data.price === "200 €") {
            /* empty */
          } else {
            setIsHover(false);
          }
        }}
      >
        <div className="flex_main">
          <StyledPriceFlex isAdjustView={isAdjustView}>
            {!isAdjustView && data.price === "200 €" ? (
              <button>{t("Most Popular")}</button>
            ) : (
              ""
            )}
            {isAdjustView ? (
              <img src={BackIcon} onClick={() => setIsAdjustView(false)} />
            ) : (
              ""
            )}
          </StyledPriceFlex>
          <div className="plan_name">
            <span>SolarHub</span>
            <div>{data.planName}</div>
          </div>
          {!isAdjustView && (
            <div className="price_detials">
              {data.price !== "200 €" ? (
                <div>
                  <div className="title">{data.titile}</div>
                </div>
              ) : (
                <div className="details_box">
                  <div className="price">
                    {formatNumber(
                      200 + objCount * 100 + pvsolCount * 200,
                      i18n.language
                    )}{" "}
                    €
                  </div>
                  <div className="month_status">
                    {" "}
                    {t("monthly subscription")}
                  </div>

                  <div className="discount_block">
                    <div className="discount_title">
                      {" "}
                      20% <span>{t("discount")}</span>{" "}
                    </div>
                    <div className="discount_description">
                      {" "}
                      1.920€ {t("yearly subscription")}{" "}
                    </div>
                  </div>
                  <div className="discount_block">
                    <div className="discount_title">
                      {" "}
                      25% <span>{t("discount")}</span>{" "}
                    </div>
                    <div className="discount_description">
                      {" "}
                      3.600€ 2-{t("yearly subscription")}{" "}
                    </div>
                  </div>

                  <div className="title">
                    {" "}
                    {4 + objCount + pvsolCount * 2} x {t("Planing data (obj)")}{" "}
                  </div>
                  <div className="title"> or </div>
                  <div className="title">
                    {" "}
                    {(2 + objCount / 2 + pvsolCount)?.toFixed()} x{" "}
                    {t("PV Sol file")}
                  </div>
                </div>
              )}
            </div>
          )}

          {!isAdjustView && (
            <button
              className="expand_button"
              onClick={() => setShowBulletPoints(!showBulletPoints)}
            >
              {t("Included in offer")}
              {!showBulletPoints ? <DownOutlined /> : <UpOutlined />}
            </button>
          )}

          {showBulletPoints && !isAdjustView && (
            <div className="bullet">
              {data.bulletPoint.map((data: string, index: number) => {
                return (
                  <div className="bullet_flex" key={index}>
                    <div className="circle">
                      {isHover ? (
                        <TickMarkBlack />
                      ) : (
                        <TickMark className="tickmark" />
                      )}
                    </div>
                    <div className="point_name">{data}</div>
                  </div>
                );
              })}
            </div>
          )}

          {isAdjustView && (
            <div className="adjust_view_content">
              <Checkbox
                onChange={(e) => handleIncreaseMonthlyChange(e.target.checked)}
                checked={increaseMonthly}
              >
                {t("Increase every month")}
              </Checkbox>

              {increaseMonthly && (
                <div className="sub_checkbox">
                  <div className="price_warn">
                    {objCount + pvsolCount}{" "}
                    {t("additional buildings per month")}
                  </div>
                  <div className="price_warn">
                    {formatNumber(
                      200 + objCount * 100 + pvsolCount * 200,
                      i18n.language
                    )}
                    € {t("monthly")}
                  </div>
                  <Checkbox
                    onChange={(e) => handleIncreaseObjChange(e.target.checked)}
                  >
                    100€ {t("OBJ Download for own planning (monthly)")}
                  </Checkbox>
                  {increaseObj && (
                    <Row>
                      <Col span={18}>
                        <Slider
                          value={objCount}
                          onChange={handleObjCountChange}
                        />
                      </Col>
                      <Col span={2}>
                        <InputNumber
                          style={{
                            margin: "0 4px",
                            width: "50px",
                            borderRadius: "16px", // Add border radius
                            background: "#fafafa",
                          }}
                          width={2}
                          value={objCount}
                          onChange={handleObjCountChange}
                        />
                      </Col>
                    </Row>
                  )}

                  <Checkbox
                    onChange={(e) =>
                      handleIncreasePvsolChange(e.target.checked)
                    }
                    style={{ marginLeft: "0px" }}
                  >
                    200€ {t("PVsol Download for own planning (monthly)")}
                  </Checkbox>
                  {increasePvsol && (
                    <Row>
                      <Col span={18}>
                        <Slider
                          value={pvsolCount}
                          onChange={handlePvsolCountChange}
                        />
                      </Col>
                      <Col span={2}>
                        <InputNumber
                          style={{
                            margin: "0 4px",
                            width: "50px",
                            borderRadius: "16px", // Add border radius
                            background: "#fafafa",
                          }}
                          width={2}
                          value={pvsolCount}
                          onChange={handlePvsolCountChange}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              )}

              <Checkbox
                onChange={(e) =>
                  handleIncreaseThisMonthChange(e.target.checked)
                }
                checked={increaseThisMonth}
                style={{ marginLeft: "0px" }}
              >
                {t("Increase only this month")}
              </Checkbox>

              {increaseThisMonth && (
                <div className="sub_checkbox">
                  <div className="price_warn">
                    {objCount + pvsolCount}{" "}
                    {t("additional buildings this month")}
                  </div>
                  <div className="price_warn">
                    {formatNumber(
                      200 + objCount * 100 + pvsolCount * 200,
                      i18n.language
                    )}
                    € {t("one-time payment")}
                  </div>
                  <Checkbox
                    onChange={(e) => handleIncreaseObjChange(e.target.checked)}
                  >
                    100€ {t("OBJ Download for own planning")}
                  </Checkbox>
                  {increaseObj && (
                    <Row>
                      <Col span={18}>
                        <Slider
                          value={objCount}
                          onChange={handleObjCountChange}
                        />
                      </Col>
                      <Col span={2}>
                        <InputNumber
                          style={{
                            margin: "0 4px",
                            width: "50px",
                            borderRadius: "16px", // Add border radius
                            background: "#fafafa",
                          }}
                          width={2}
                          value={objCount}
                          onChange={handleObjCountChange}
                        />
                      </Col>
                    </Row>
                  )}

                  <Checkbox
                    onChange={(e) =>
                      handleIncreasePvsolChange(e.target.checked)
                    }
                    style={{ marginLeft: "0px" }}
                  >
                    200€ {t("PVsol Download for own planning")}
                  </Checkbox>
                  {increasePvsol && (
                    <Row>
                      <Col span={18}>
                        <Slider
                          value={pvsolCount}
                          onChange={handlePvsolCountChange}
                        />
                      </Col>
                      <Col span={2}>
                        <InputNumber
                          style={{
                            margin: "0 4px",
                            width: "50px",
                            borderRadius: "16px", // Add border radius
                            background: "#fafafa",
                          }}
                          width={2}
                          value={pvsolCount}
                          onChange={handlePvsolCountChange}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </div>
          )}

          {data.price === "200 €" && (
            <button
              className="adjust_button"
              onClick={() => setIsAdjustView(!isAdjustView)}
            >
              {t("Adjust Plan")}
            </button>
          )}

          {!isAdjustView && (
            <button className="choos_button" onClick={handlePurchasePlan}>
              {t("Select Plan")}
            </button>
          )}
        </div>
      </div>
    </PayWallWrapper>
  );
};

export default DetailsPlan;
