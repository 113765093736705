import { MouseEventHandler } from "react";

import * as S from "./CloseButton.styles";
import { VariantTypes } from "./CloseButton.types";

interface CloseButtonProps {
  onClick: MouseEventHandler;
  variant?: VariantTypes;
}
const CloseButton: React.FC<CloseButtonProps> = (props: CloseButtonProps) => {
  const { onClick, variant } = props;

  return (
    <S.CloseButtonDiv onClick={onClick} variant={variant as VariantTypes}>
      <div className="line l1"></div>
      <div className="line l2"></div>
    </S.CloseButtonDiv>
  );
};

CloseButton.defaultProps = {
  variant: "circle-hoverable",
};

export default CloseButton;
