import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { PRODUCT_SLICE_NAME, ProductState, initialState } from "./models";
import { getProducts, updateProductDetails } from "./actionCreators";

export const productSlice = createSlice({
  name: PRODUCT_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(getProducts), (state: ProductState, action) => {
        const { payload } = action;
        state.isLoading = false;

        state.products = payload?.data;
      })
      .addMatcher(
        isRejected(getProducts, updateProductDetails),
        (state: ProductState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          console.log(error, "error");
        }
      )
      .addMatcher(
        isPending(getProducts, updateProductDetails),
        (state: ProductState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isFulfilled(updateProductDetails),
        (state: ProductState, action) => {
          state.isLoading = false;
        }
      );
  },
});

export default productSlice.reducer;
