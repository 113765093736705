import { ApiResponse, httpClient } from "shared/utils";
import {
  IGetAllQuotesDTO,
  IGetAllQuotesMapViewDTO,
  IGetAllQuotesMapViewResponse,
  IGetFilterDataResponse,
  IGetQuoteByIdDTO,
  IGetQuotesAllResponse,
  IGetQuotesByIdResponse,
  IGetTotalDTO,
  IGetTotalResponse,
  getQuotesEndpoints,
} from "models/quotes.model";
import { IGetInstallerResponse } from "models/installer.model";

class QuotesAPI {
  static getAllQuotes({
    page,
    limit,
    sortColumn = "profiles.green.rating",
    sortOrder = "desc",
    ...data
  }: IGetAllQuotesDTO): ApiResponse<IGetQuotesAllResponse> {
    const { GET_ALL_QUOTES } = getQuotesEndpoints({
      page,
      limit,
      sortColumn,
      sortOrder,
    });
    return httpClient.put<IGetQuotesAllResponse>(GET_ALL_QUOTES, { ...data });
  }

  static getAllQuotesMapView({
    ...data
  }: IGetAllQuotesMapViewDTO): ApiResponse<IGetAllQuotesMapViewResponse> {
    const { GET_ALL_QUOTES_MAP_VIEW } = getQuotesEndpoints({});
    return httpClient.post<IGetAllQuotesMapViewResponse>(
      GET_ALL_QUOTES_MAP_VIEW,
      {
        ...data,
      }
    );
  }

  static getQuoteById({
    id,
  }: IGetQuoteByIdDTO): ApiResponse<IGetQuotesByIdResponse> {
    const { GET_QUOTE_BY_ID } = getQuotesEndpoints({ id });
    return httpClient.get<IGetQuotesByIdResponse>(GET_QUOTE_BY_ID);
  }

  static getFilterData(): ApiResponse<IGetFilterDataResponse> {
    const { GET_FILTER_DATA } = getQuotesEndpoints({});
    return httpClient.post<IGetFilterDataResponse>(GET_FILTER_DATA);
  }

  static getAllInstaller({
    page,
    limit,
    ...data
  }: IGetAllQuotesDTO): ApiResponse<IGetInstallerResponse> {
    const { GET_ALL_INSTALLER } = getQuotesEndpoints({ page, limit });
    return httpClient.put<IGetInstallerResponse>(GET_ALL_INSTALLER, {
      ...data,
    });
  }

  static getTotal({ ...data }: IGetTotalDTO): ApiResponse<IGetTotalResponse> {
    const { GET_TOTAL } = getQuotesEndpoints({});
    return httpClient.post<IGetTotalResponse>(GET_TOTAL, {
      ...data,
    });
  }

  static updateQuote(data: any): ApiResponse<any> {
    const { UPDATE } = getQuotesEndpoints({});
    return httpClient.put<any>(UPDATE, data);
  }
}

export default QuotesAPI;
