import {
  ICreateCheckoutDTO,
  ICreateCheckoutResponse,
  ICreatePriceDTO,
  ICreatePriceResponse,
  IGetSubscriptionListResponse,
  getSubscriptionEndpoints,
} from "models/subscription.model";
import { ApiResponse, httpClient } from "shared/utils";

class SubscriptionAPI {
  static createPrice(data: ICreatePriceDTO): ApiResponse<ICreatePriceResponse> {
    const { CREATE_PRICE } = getSubscriptionEndpoints();
    return httpClient.post<ICreatePriceResponse>(CREATE_PRICE, data);
  }
  static createCheckout(
    data: ICreateCheckoutDTO
  ): ApiResponse<ICreateCheckoutResponse> {
    const { CREATE_CHECKOUT } = getSubscriptionEndpoints();
    return httpClient.post<ICreateCheckoutResponse>(CREATE_CHECKOUT, data);
  }
  static getSubscriptionList(): ApiResponse<IGetSubscriptionListResponse> {
    const { SUBSCRIPTION_LIST } = getSubscriptionEndpoints();
    return httpClient.get<IGetSubscriptionListResponse>(SUBSCRIPTION_LIST);
  }
}

export default SubscriptionAPI;
