import { IApiError } from "models/apiError.model";
import {
  IAuthResponse,
  ICustomerOffer,
  IGetFreeDownloadCount,
  ILoginDTO,
  ISendEmail,
  IStorePdf,
  IUpdateDownloadCountDTO,
  IUserDetailsResponse,
} from "models/user.model";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { USER_SLICE_NAME } from "./models";
import { UserAPI } from "api";
import { formatApiError } from "shared/utils";

export const loginUser = createAsyncThunk<
  IAuthResponse,
  ILoginDTO,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/login`,
  async (data: ILoginDTO) => {
    const response = await UserAPI.login(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getUserDetails = createAsyncThunk<
  IUserDetailsResponse,
  void,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/getUserDetails`,
  async () => {
    const response = await UserAPI.getUserDetails();
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateDownloadCount = createAsyncThunk<
  IUserDetailsResponse,
  IUpdateDownloadCountDTO,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/updateDownloadCount`,
  async (data: IUpdateDownloadCountDTO) => {
    const response = await UserAPI.updateDownloadCount(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getFreeDownloadCount = createAsyncThunk<
  IGetFreeDownloadCount,
  void,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/getcounts`,
  async () => {
    const response = await UserAPI.getFreeDownloadCount();
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateInstallerUser = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/installer`,
  async (data: any) => {
    const response = await UserAPI.updateInstallerUser(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const sendEmailToInstaller = createAsyncThunk<
  any,
  ISendEmail,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/sendEmailToInstaller`,
  async (data: ISendEmail) => {
    const response = await UserAPI.sendEmailToInstaller(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const storePdfData = createAsyncThunk<
  any,
  IStorePdf,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/storePdfData`,
  async (data: IStorePdf) => {
    const response = await UserAPI.storePdfData(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const sendCustomerOffer = createAsyncThunk<
  any,
  ICustomerOffer,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/sendCustomerOffer`,
  async (data: ICustomerOffer) => {
    const response = await UserAPI.sendCustomerOffer(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const getAllPanels = createAsyncThunk<
  any,
  void,
  { serializedErrorType: IApiError }
>(
  `${USER_SLICE_NAME}/getAllPanels`,
  async () => {
    const response = await UserAPI.getgetAllPanels();
    return response.data;
  },
  { serializeError: formatApiError }
);
