import { ApiResponse, httpClient } from "shared/utils";
import { getMediaEndpoints } from "models/media.model";

class MediaAPI {
  static addMedia(data: any): any {
    const { ADD_MEDIA } = getMediaEndpoints();
    return httpClient.post<any>(ADD_MEDIA, data, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
      },
    });
  }
}

export default MediaAPI;
