import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { QUOTES_SLICE_NAME, QuotesState, initialState } from "./models";
import {
  getAllQuotes,
  getAllQuotesMapView,
  getQuoteById,
  loadMoreQuotes,
} from "./actionCreators";
import { projectStatus } from "models/quotes.model";

export const quotesSlice = createSlice({
  name: QUOTES_SLICE_NAME,
  initialState,
  reducers: {
    setQuoteDetails(state, action) {
      const { payload } = action;
      const { id, quote } = payload;
      state.quotes = state.quotes.map((q) =>
        q._id === id ? { ...q, ...quote } : q
      );
    },
    removeQuoteDetails(state, action) {
      const { payload } = action;
      const { id } = payload;
      state.quotes = state.quotes.filter((q) =>
        q._id !== id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(getAllQuotes), (state: QuotesState, action) => {
        const { payload } = action;
        state.quotes = payload.data.docs;
        state.pagination = payload.data.pagination;
        state.maxMin = payload.data.maxMin[0];
        state.isLoading = false;
      })
      .addMatcher(
        isPending(
          getAllQuotes,
          loadMoreQuotes,
          getAllQuotesMapView,
          getQuoteById
        ),
        (state: QuotesState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(
          getAllQuotes,
          loadMoreQuotes,
          getAllQuotesMapView,
          getQuoteById
        ),
        (state: QuotesState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          console.log(error, "error");
        }
      )
      .addMatcher(isFulfilled(loadMoreQuotes), (state: QuotesState, action) => {
        const { payload } = action;
        state.quotes = [...state.quotes, ...payload.data.docs];
        state.pagination = payload.data.pagination;
        state.maxMin = payload.data.maxMin[0];
        state.isLoading = false;
      })
      .addMatcher(
        isFulfilled(getAllQuotesMapView),
        (state: QuotesState, action) => {
          const { payload } = action;
          state.mapView = payload.data.docs;
          state.isLoading = false;
        }
      )
      .addMatcher(isFulfilled(getQuoteById), (state: QuotesState, action) => {
        const { payload } = action;
        state.selectedQuote = payload.data;
        state.isLoading = false;
      });
  },
});

export const { setQuoteDetails, removeQuoteDetails } = quotesSlice.actions;

export default quotesSlice.reducer;
