import { English, German } from "assets";
import { ReactNode } from "react";

export enum LangaugeItems {
  ENGLISH = "/images/English.png",
  GERMAN = "/images/German.png",
}

type TConfigItem = {
  titleKey: string;
  icon: string;
  key: LangaugeItems;
};


export const getLangaugeMenuConfig = () => [
  {
    key: LangaugeItems.ENGLISH,
    title: "English",
    flag: "/images/English.png", 
  },
  {
    key: LangaugeItems.GERMAN,
    title: "Deutsch",
    flag: "/images/German.png", 
  },
];


