import { createAsyncThunk } from "@reduxjs/toolkit";
import { IApiError } from "models/apiError.model";
import { EMAIL_TEMPLATE_SLICE_NAME, EmailTemplate } from "./models";
import { EmailTemplateAPI } from "api";
import { formatApiError } from "shared/utils";
import { IGetEmailTemplatesResponse } from "models/emailTemplate.model";

export const getEmailTemplates = createAsyncThunk<
  IGetEmailTemplatesResponse,
  { installerId?: string },
  { serializedErrorType: IApiError }
>(
  `${EMAIL_TEMPLATE_SLICE_NAME}/getEmailTemplates`,
  async () => {
    const response = await EmailTemplateAPI.getEmailTemplates();
    return response.data;
  },
  { serializeError: formatApiError }
);
