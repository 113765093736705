import storage from "redux-persist/lib/storage";
import { USER_SLICE_NAME } from "redux/user/models";

const persistConfig = {
  key: "root", 
  storage,
  whitelist: [USER_SLICE_NAME],
};

export default persistConfig;
