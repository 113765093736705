import { message as AntdMessage } from "antd";
import { createGlobalStyle } from "styled-components";

interface IShowErrorToast {
  message: string;
}

export const ToastGlobalStyle = createGlobalStyle`
  .exped-review-toast-notification {
    text-align: end;
    margin: 0 1rem;

    &:first-child {
      margin: 3.5rem 1rem 0;
    }

  }
`;

const showErrorToast = ({ message }: IShowErrorToast) => {
  if (typeof window === "undefined") {
    return null;
  }
  return AntdMessage.error({
    content: message || "Error",
    // className: "exped-review-toast-notification",
  });
};

export default showErrorToast;
