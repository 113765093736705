export interface EmailTemplate {
  _id: string;
  templateName: string;
  content: string;
  installers: string[];
}

export enum EmailTemplateEndpoints {
  EMAIL_TEMPLATE="/email-template",
}

export interface IgetProductEndpoints {
  installerId?: string;
  emailTemplateId?: string;
}

export interface IGetEmailTemplatesResponse {
  data: EmailTemplate[];
}

export const getEmailTemplateEndpoints = ({
  installerId,
  emailTemplateId,
}: IgetProductEndpoints) => ({
  GET_EMAIL_TEMPLATES: `${EmailTemplateEndpoints.EMAIL_TEMPLATE}`,
});
