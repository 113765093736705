import { IBodyForInstallerMultipleAccess } from "models/installer.model";
import {
  IAuthResponse,
  IGetFreeDownloadCount,
  ILoginDTO,
  IUpdateDownloadCountDTO,
  IUserDetailsResponse,
  UserEndpoints,
  IInstaller,
  ISendEmail,
  IStorePdf,
  ICustomerOffer,
} from "models/user.model";
import { ApiResponse, httpClient } from "shared/utils";

class UserAPI {
  static login(data: ILoginDTO): ApiResponse<IAuthResponse> {
    return httpClient.post<IAuthResponse>(UserEndpoints.LOGIN, data);
  }
  static updateInstallerMultipleAccess(
    data: IBodyForInstallerMultipleAccess,
    iid: string | undefined
  ): ApiResponse<IAuthResponse> {
    return httpClient.post<IAuthResponse>(
      `${UserEndpoints.MULTIPLEACCESS}/${iid}`,
      data
    );
  }
  static getUserDetails(): ApiResponse<IUserDetailsResponse> {
    return httpClient.get<IUserDetailsResponse>(UserEndpoints.USER);
  }
  static updateDownloadCount(
    data: IUpdateDownloadCountDTO
  ): ApiResponse<IUserDetailsResponse> {
    return httpClient.put<IUserDetailsResponse>(
      UserEndpoints.DOWNLOAD_COUNT,
      data
    );
  }
  static getFreeDownloadCount(): ApiResponse<IGetFreeDownloadCount> {
    return httpClient.get<IGetFreeDownloadCount>(
      UserEndpoints.FREE_DOWNLOAD_COUNT
    );
  }
  static updateInstallerUser(
    data: IInstaller
  ): ApiResponse<IUserDetailsResponse> {
    return httpClient.put<IUserDetailsResponse>(
      `${UserEndpoints.INSTALLER_UPDATE}/${data.installerId}`,
      data
    );
  }

  static sendEmailToInstaller(data: ISendEmail): ApiResponse<any> {
    return httpClient.post<any>(`${UserEndpoints.SEND_EMAIL}`, data);
  }

  static sendCustomerOfferEmail(data: ISendEmail): ApiResponse<any> {
    return httpClient.post<any>(`${UserEndpoints.SEND_OFFER_MAIL}`, data);
  }

  static storePdfData(data: IStorePdf): ApiResponse<any> {
    return httpClient.post<any>(`${UserEndpoints.STORE_PDF_DATA}`, data);
  }

  static sendCustomerOffer(data: ICustomerOffer): ApiResponse<any> {
    return httpClient.post<any>(`${UserEndpoints.CUSTOMER_OFFER}`, data);
  }

  static generateInstallerPDF(data: ICustomerOffer): ApiResponse<any> {
    return httpClient.post<any>(`${UserEndpoints.INSTALLERS_PDF}`, data);
  }

  static getgetAllPanels(): ApiResponse<any> {
    return httpClient.get<any>(UserEndpoints.GET_ALL_PANEL);
  }
}

export default UserAPI;
