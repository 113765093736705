import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import {
  SUBSCRIPTION_SLICE_NAME,
  SubscriptionState,
  initialState,
} from "./models";
import {
  createCheckout,
  createPrice,
  getSubscriptionList,
} from "./actionCreators";
import { useAppDispatch } from "redux/store";

export const subscriptionSlice = createSlice({
  name: SUBSCRIPTION_SLICE_NAME,
  initialState,
  reducers: {
    openPaywallModal(state) {
      state.paywallModal = true;
    },
    closePaywallModal(state) {
      state.paywallModal = false;
    },
    openHubspotModal(state) {
      state.hubspotModal = true;
    },
    closeHubspotModal(state) {
      state.hubspotModal = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isFulfilled(createPrice),
        (state: SubscriptionState, action) => {
          const { payload } = action;
          state.priceId = payload?.data?.id;
        }
      )
      .addMatcher(
        isFulfilled(getSubscriptionList),
        (state: SubscriptionState, action) => {
          const { payload } = action;
          state.metadata =
            payload?.data?.subscriptions?.data[0]?.items?.data[0]?.plan?.metadata;
        }
      )
      .addMatcher(
        isFulfilled(createCheckout),
        (state: SubscriptionState, action) => {
          const { payload } = action;
          state.paywallModal = false;
          window.open(payload?.data?.url);
        }
      )
      .addMatcher(
        isPending(createCheckout, createPrice, getSubscriptionList),
        (state: SubscriptionState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(createCheckout, createPrice, getSubscriptionList),
        (state: SubscriptionState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          console.log(error, "error");
        }
      )
      .addMatcher(
        isRejected(getSubscriptionList),
        (state: SubscriptionState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          state.metadata = {
            obj: "0",
            pvsol: "0",
          };
          console.log(error, "error");
        }
      );
  },
});

export const {
  openPaywallModal,
  closePaywallModal,
  openHubspotModal,
  closeHubspotModal,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
