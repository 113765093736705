import styled from "styled-components/macro";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1031px;
  padding-top: 2rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  background: rgb(45, 71, 100);
  justify-content: center;
  align-items: center;
  min-width: 1000px;
  @media (max-width: 600px) {
    min-width: 100%;
  }
  .meetings-iframe-container {
    width: 100%;
    @media (max-width: 600px) {
      min-width: 100%;
    }
  }

  iframe {
    min-height: 516px;
    height: 756px;
    border: none;
    body {
      .m-x-10 {
        margin: 0 !important;
      }
      .fullsize-container {
        max-width: 100% !important;
      }
    }
    @media (max-width: 600px) {
      min-width: 100%;
    }
  }

  .p-top-4,
  .p-y-4 {
  }
  .fullsize-container {
    max-width: 100% !important;
  }
`;
