import { createAsyncThunk } from "@reduxjs/toolkit";
import { IApiError } from "models/apiError.model";
import { PRODUCT_SLICE_NAME } from "./models";
import { ProductAPI } from "api";
import { formatApiError } from "shared/utils";
import {
  IAddInstallerDTO,
  IDuplicareProductDTO,
  IGetProductsResponse,
  IUpdateBulkPricesDTO,
  IUpdateInstallerDTO,
} from "models/product.model";

export const getProducts = createAsyncThunk<
  IGetProductsResponse,
  { installerId?: string },
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/getProducts`,
  async (id) => {
    const response = await ProductAPI.getProducts(id);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const addInstallerProduct = createAsyncThunk<
  any,
  IAddInstallerDTO,
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/addInstallerProduct`,
  async (data: IAddInstallerDTO) => {
    const response = await ProductAPI.addInstallerProduct(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateInstallerProduct = createAsyncThunk<
  any,
  IUpdateInstallerDTO,
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/updateInstallerProduct`,
  async (data: IUpdateInstallerDTO) => {
    const response = await ProductAPI.updateInstallerProduct(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const removeInstallerProduct = createAsyncThunk<
  any,
  IAddInstallerDTO,
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/removeInstallerProduct`,
  async (data: IAddInstallerDTO) => {
    const response = await ProductAPI.removeInstallerProduct(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const duplicateInstallerProduct = createAsyncThunk<
  any,
  IDuplicareProductDTO,
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/duplicateInstallerProduct`,
  async (data: IDuplicareProductDTO) => {
    const response = await ProductAPI.duplicateInstallerProduct(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateProductDetails = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/updateProductDetails`,
  async (data: any) => {
    const response = await ProductAPI.updateProductDetails(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const deleteProduct = createAsyncThunk<
  any,
  any,
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/deleteProduct`,
  async (data: any) => {
    const response = await ProductAPI.deleteProduct(data);
    return response.data;
  },
  { serializeError: formatApiError }
);

export const updateBulkPrices = createAsyncThunk<
  IGetProductsResponse,
  IUpdateBulkPricesDTO,
  { serializedErrorType: IApiError }
>(
  `${PRODUCT_SLICE_NAME}/updateBulkPrices`,
  async (data: IUpdateBulkPricesDTO) => {
    const response = await ProductAPI.updateBulkPrices(data);
    return response.data;
  },
  { serializeError: formatApiError }
);
