import React from 'react';
import { Radio, RadioProps } from 'antd';

export interface IRadioProps extends RadioProps {
  placeholder?: string;
}

const CustomizeRadio = ({
  placeholder,
  children,
  ...props
}: IRadioProps): JSX.Element => (
  <Radio {...props}>{placeholder || children}</Radio>
);

export default CustomizeRadio;
